// Importe as constantes de ação

import {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    UPDATE_USER_ROLE,
    LOGOUT_SUCCESS,
  } from './actions';
  
  // Estado inicial
  const initialState = {
    isLogged : false,
    isLoading: false,
    errorMessage:'',
    user: {role:'basico',nome:'Tiago Venegas' }// Valor inicial do user.role
  }
  
  export default function authReducer(state = initialState, action: { type: any; payload: any; }) {
    switch (action.type) {
      case LOGIN_START:
        return { ...state, isLoading: true };
     case UPDATE_USER_ROLE:
        return {
          ...state,
          user: action.payload // Atualiza o valor do user.role com o payload da ação
        };
      case LOGIN_SUCCESS:
        console.log('LOGIN_SUCCESS')
        return { ...state, isLoading: false,  isLogged : true };
      case LOGOUT_SUCCESS:
        console.log('LOGOUT_SUCCESS')
        return { ...state, isLoading: false,  isLogged : false };
      case LOGIN_FAILED:
        console.log('LOGIN_FAILED', action.payload)
        return { ...state, isLoading: false,  isLogged : false , errorMessage: action.payload };
      
      // E assim por diante para os outros casos...
      default:
        return state;
    }
  }
  
    