import React, { ChangeEvent, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  Button, Container, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { CloudUpload, Email } from '@material-ui/icons';
import { ConnectedProps, connect } from 'react-redux';
import { updateUsuario, getImagesByUser, setImageProfile } from './../redux/usuarios/actions';
import { getUser  } from './../redux/user/actions';
import { fetchEmpresas } from './../redux/empresas/actions';
import { deleteUsuario } from './../redux/usuarios/actions';
import { useNavigate } from 'react-router-dom';
import {serverConfig} from './../api/apiConfig';
import Avatar from '@mui/material/Avatar';
import { Divider, MenuItem } from '@mui/material';
import { getStorage, ref } from "firebase/storage";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
import {auth, initApp, update, find, updateProfileImg} from './../firebase';



const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    
    textAlign: 'center',
  },
  form_content: {
    marginTop:20,
      display: 'flex',
      flexDirection: 'column',
      aligContent: 'center',
      justifyContent: 'space-around',
      alignItems: 'stretch',
  
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button:{
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
const UsuarioPage: React.FC<PropsFromRedux>  = ({usuario,isLoadingUsuario, getUser, imagesByUser, getImagesByUser,setImageProfile, updateUsuario}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [newUsuarioPassword, setNewUsuarioPassword] = React.useState<any>('');
  const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>('');
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  const permissoesList = ['administrador','gerente','basico']
  const [contador, setContador] = React.useState(0);
  const [file, setFile] = React.useState<any>();
  const [image, setImage] = React.useState<any>('');
  const [update, setUpdate] = React.useState<any>(false);
  

  useEffect(() => {
    if(update && !isLoadingUsuario){
      setUpdate(false)
      getUser()
    }
  },[isLoadingUsuario])
  useEffect(() => {
    console.log("imagesByUser useEffect", imagesByUser)
    const imagesList:any = [];
    let cont = 0
    imagesByUser.map((item:any, index:any)=>{
      cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: index,
        title: 'Usuario',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
        
      }
      imagesList.push(slide)
    })
    console.log('imagesList ==>>> ', imagesList)
    console.log('cont ==>>> ', cont)
    setContador(cont)
    if(imagesList.length){
      const image = imagesList[0].image
      setImage(image)
      setImageProfile(image)
    }
    //setSlides(imagesList)
   
  },[imagesByUser]);
  useEffect(()=>{
    console.log('getImagesByUser =====>>>>>> ', usuario._id)
    getImagesByUser(usuario._id)
  },[getImagesByUser])
  
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]))
    }
  };

  useEffect(()=>{
    let perm = 'basico'
    if(usuario.permissoes?.admin) {
      perm = 'administrador'
    }else if(usuario.permissoes?.manager){
      perm = 'gerente'
    }
    setNewUsuarioPermissoes(perm)
    setEditUsuarioData(usuario)
  }, [usuario])

  const makePermissoes = ()=>{
    if(newUsuarioPermissoes == 'administrador'){
      return {admin : true}
    } else if(newUsuarioPermissoes == 'basico'){
      return {basic : true}
    } else if(newUsuarioPermissoes == 'gerente'){
      return {manager : true}
    } else{
      return {}
    }
  }

  const saveData = (url:any)=>{
    console.log('url',url)
    updateProfileImg(url)
  }

  const uploadFile = () => {
    const storage = getStorage();
    if (file === null) {
      console.log("Please select an image");
      return;
    }
    const imageRef = storageRef(storage, `users/${usuario.id}`);
    console.log(file)
    uploadBytes(imageRef, file)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((url) => {
            console.log('url ============> ',url)
            saveData(url);
          })
          .catch((error) => {
            console.log('error upload img',error.message);
          });
      })
      .catch((error) => {
        console.log('error ==> upload img',error.message);
      });
  };

  const handleUpdate = async () => {


    

    


    //setUpdate(true)
    console.log('editUsuarioData',editUsuarioData)
    const dataToSend = editUsuarioData
    const permissoes = makePermissoes()
    dataToSend.permissoes = permissoes
    if(file){
      uploadFile()
      console.log(file)
      dataToSend.file = file;
      dataToSend.contador = (contador+1);
    } 
    console.log('dataToSend',dataToSend)

    //updateUsuario(dataToSend)
    
    
  };

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };
  
  const validateConfirmPassword = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };

  return (
    <Container className={classes.container}>
      <div className={classes.content}>
      <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        <Typography variant="h4" className={'title'}>
          Bem-vindo à área do usuário
        </Typography>
        {/* Adicione os componentes e funcionalidades específicas da página do usuario */}
        <div className={classes.form_content}>
          <Avatar sx={{ width: 112, height: 112 }} style={{alignSelf:'center'}} alt={usuario.nome} src={image||usuario.photoURL} />
        <div>
        {/* {file&&file.name ? (<div>{file && `${file.name} - ${file.type}`}</div>):null} */}
        <div className="file-upload">
              {/* <img src={uploadImg} alt="upload" /> */}
              <CloudUpload />
              <h3> {file?.name || "Clique para escolher uma imagem"}</h3>
              <p>Tamanho máximo do arquivo 10mb</p>
              <input type="file" onChange={handleFileChange} />
            </div>

            
        </div>
          <TextField
            style={{marginBottom:5}}
            label="Nome do usuário"
            value={editUsuarioData.nome}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, nome: e.target.value })}
          />
          <TextField
          style={{marginBottom:5}}
            label="E-mail"
            value={editUsuarioData.email}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(editUsuarioData.email)}
            helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
          />
          
          { passwordOpen ? (
            <>
              <TextField
              style={{margin:5}}
              label="Senha"
              value={newUsuarioPassword}
              onChange={(e) => {
                setEditUsuarioData({ ...editUsuarioData, password: e.target.value })
                setNewUsuarioPassword(e.target.value)
              }}
              type="password"
              error={!validatePassword(newUsuarioPassword)}
              helperText={!validatePassword(newUsuarioPassword) && 'A senha deve ter pelo menos 6 caracteres'}
            />
            <TextField
            style={{margin:5}}
              label="Confirmar Senha"
              value={newUsuarioConfirmPassword}
              onChange={(e) => {
                setEditUsuarioData({ ...editUsuarioData, confirmPassword: e.target.value })
                setNewUsuarioConfirmPassword(e.target.value )
              }}
              type="password"
              error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
              helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'As senhas não correspondem'}
            />
            </>

          ) : (<Button className={classes.button} onClick={() => setPasswordOpen(true)}>Alterar senha</Button>)
            
          }

          
            {/* <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
              <Select
                value={newUsuarioPermissoes}
                onChange={(e:any) => setNewUsuarioPermissoes( e.target.value )}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}


            <Button className={classes.button} style={{margin:15,}} onClick={()=>{handleUpdate()}}>Gravar</Button>


          
          

            {/* <TextField
              label="Empresa"
              value={editUsuarioData.empresa}
              onChange={(e) => setNewUsuarioEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editUsuarioData.empresa == ""}

              helperText={
                editUsuarioData.empresa=="" &&
                'Empresa inválida'
              }
            /> */}
        </div>
        <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user.user, 
  isLoadingUsuarios : state.usuarios.isLoadingUsuarios,
  isLoadingUsuario : state.usuarios.isLoadingUsuario,
  isLoadingCreateUsuario : state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas,
  imagesByUser : state.usuarios.imagesByUser,
});

const mapDispatchToProps = {
  fetchEmpresas,
  deleteUsuario,
  updateUsuario,
  getImagesByUser,
  setImageProfile,
  getUser
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UsuarioPage);


