const path = 'http://localhost:3000/';
export const getEmpresas = async () => {
    const response = await fetch(path + 'empresas');
    return await response.json();
  }
  
  export const getEmpresaById = async (id: any) => {
    const response = await fetch(path + `empresas/${id}`);
    return await response.json();
  }
  
  export const createEmpresa = async (empresa: any) => {
    const response = await fetch(path + 'empresas', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(empresa)
    });
    return await response.json();
  }
  
  // E assim por diante para os outros endpoints...
  