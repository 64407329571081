import React , { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { connect, ConnectedProps } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import Chart from './../components/Elements/Chart';
import Deposits from './../components/Elements/Deposits';
import Itens from './../components/Elements/Itens';
import Orders from './../components/Elements/Orders';
import { useNavigate } from 'react-router-dom';
import { toggleDrawerStart } from '../redux/global/actions';

import Title from './../components/Elements/Title';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { axisClasses } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';

import Stack from '@mui/material/Stack';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import moment from 'moment'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { Autocomplete, ListItemButton, ListSubheader } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import {auth, initApp, update, find, findAll, findQuery, makeCount, makeSum} from './../firebase';


function Copyright(props:any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://greentag.com.br/">
        Greentag.com.br
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const zero = (n:any)=>{
  if(n<10)
   {return '0'+n} else return(n)

}
const getDate = ()=>{
  const date = new Date()
  const newDate = zero(date.getDate()) + '/' + zero(Number(date.getMonth())+1) + '/' + date.getFullYear()
  return newDate
}

type PropsFromRedux = ConnectedProps<typeof connector>;
const steps = ['Crie uma unidade', 'Capture ítens', 'Faça Usuários'];

const Dashboard: React.FC<PropsFromRedux> = ({usuario}) => {
  const navigate = useNavigate();
  type PropsLista = {label:string;id:string,value:number} 

  const [dataName, setDataName] = React.useState<any>([]);
  const [dataNumber, setDataNumber] = React.useState<any>([]);
  const [inventariosTotal, setInventariosTotal] = React.useState<any>(0);
  const [empreendimentos, setEmpreendimentos] = React.useState<any>(0);
  const [investido, setInvestido] = React.useState<any>(0);
  const [lista, setLista] = React.useState<PropsLista[]>([]);
  const [lista1, setLista1] = React.useState<any>([]);
  const [listaLinha, setListaLinha] = React.useState<any[]>([]);
  const [listaLinhaData, setListaLinhaData] = React.useState<any>([]);
  const [listaLinha1, setListaLinha1] = React.useState<any[]>([]);
  const [listaLinha1Data, setListaLinha1Data] = React.useState<any>([]);
  const [listaDuplo, setListaDuplo] = React.useState<any>([]);
  const [listaDuplo1, setListaDuplo1] = React.useState<any>([]);
  const [ofertas, setOfertas] = React.useState<any>([]);
  const [lista2, setLista2] = React.useState<any>([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [hasCategory, setHasCategory] = React.useState(false);
  const [intro, setIntro] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [nomeCategoria, setNomeCategoria] = React.useState('');
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [selectedCategory, setSelectedCategory] = React.useState<any>({key:0,value:'Filtro por unidade'});
  const [sending, setSending] = React.useState<any>(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [errorMessageText, setErrorMessageText] = React.useState<any>('');
  const [aplicacoes, setAplicacoes] = React.useState<any[]>([]);

  const formatCurrency =  (value: number): string=> {
    const formattedValue = value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    return formattedValue;
  }
  useEffect(() => {
    const getAplicacoes  =async ()=>{
       console.log('getAplicacoes usuario',usuario)
       if(usuario.id)
       {
           const valor:any = await makeCount('investimentos', 'usuario', usuario.id)
           console.log('valor makeCount ==> ',valor)
           setAplicacoes(valor)
           setIsLoading(false);
           const num = await makeCount('empreendimentos','ativo',true)
           setEmpreendimentos(num) 
           const num1 = await makeSum('investimentos','usuario', usuario.id, 'valor')
           console.log('valor num1', num1)
           const formattedNum = formatCurrency(num1)
           setInvestido(formattedNum) 
       }
    }
    getAplicacoes()
   }, []);


 




  useEffect(()=>{
    //getAllInventoryStart()
  },[])

  const clearData = (d:any)=>{
    const dateTransform = (moment(d).format('YYYY, MM, DD'));
    const data = new Date(dateTransform)
    return data
  }



  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  

  const handleNext = () => {
    console.log('activeStep ===> ',activeStep)
    console.log('hasCategory ===> ',hasCategory)
    if(activeStep == 0&&!hasCategory/* &&!ofertas.length */){
      setSending(true)
      return;
    }
    console.log('passou')
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if(activeStep == steps.length -1)
      handleReset()
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setIntro(false)
  };


  const chartSetting = {
    xAxis: [
      {
        label: 'Itens da unidade',
      },
    ],
    width: 600,
    height: 400,
    
  };
  const chartSettingInv = {
    yAxis: [
      {
        label: 'Usuários',
      },
    ],
    width: 600,
    height: 400,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: 'translate(-20px, 0)',
      },
    },
  };
  const valueFormatter = (value: any) => `${value} ítens`;
  const valueFormatterName = (value: any) =>getCategoriaNome(value);
  const valueFormatterInv = (value: any) => `${value} Usuários`;
  const valueFormatterNameInv = (value: any) =>getCategoriaNome(value);
  const getCategoriaNome =(id:any)=>{

    const item = ofertas.filter((el:any)=> el._id == id)
    if(!item.length) return id
    return item[0].nome
  }
  const changeCategoria = (cat:any)=>{
    
    console.log('categoria id: ',cat)
    
    
    
  }
  return (
    
      <Box sx={{ display: 'flex', width:'100%' }}>
        <CssBaseline />
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            
                //maxHeight: 'calc(100vh - 200px)',
                
                '&::-webkit-scrollbar': {
                  width: '8px',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'green',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: 'darkgreen',
                },
              
          }}
        >
           
          {/* <Toolbar /> */}
          <Container maxWidth="lg" style={{width:'100%',alignContent:'center', textAlign:'center'}}>
            <Button className='backbutton' onClick={() => navigate('/')}>Voltar</Button>
          </Container>
          {isLoading ? (
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            ) : null
          }
          {
            intro ?(
              
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                 <Grid container spacing={3}>
                {/* produtos */}

                <Grid item xs={12} md={12} lg={12} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 500,
                    }}
                  >

                  <Box sx={{ width: '100%' }}>
                      <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const stepProps: { completed?: boolean } = {};
                          const labelProps: {
                            optional?: React.ReactNode;
                          } = {};
                          if (isStepOptional(index)) {
                            labelProps.optional = (
                              <Typography variant="caption">opcional</Typography>
                            );
                          }
                          if (isStepSkipped(index)) {
                            stepProps.completed = false;
                          }
                          return (
                            <Step key={label} {...stepProps}>
                              <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                        {activeStep === steps.length ? (
                          <React.Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                              Todos etapas completadas - Pronto para fazer capturas de ítens pelo App.
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                              <Box sx={{ flex: '1 1 auto' }} />
                              <Button onClick={handleReset}>Finalizar</Button>
                            </Box>
                          </React.Fragment>
                        ) : (
                  <React.Fragment >
                    <Typography sx={{ mt: 2, mb: 1 }}>Passo {activeStep + 1}</Typography>
                    {
                      activeStep == 0 ? (
                        <Box sx={{   margin:15 }} style={{display: 'flex',flexDirection: 'column',height:50, alignContent: 'center', justifyContent: 'space-around', alignItems: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
                          <Typography sx={{ mt: 2, mb: 1 }}>Ítens quando capturados pela primeira vez precisam ser referenciados a uma Unidade. </Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}>Unidades agrupam ítens para que possam ser inventariados posteriormente.</Typography>
                          <Typography color={'#008800'} sx={{ mt: 2, mb: 1 }}>Crie sua primeira Unidade agora.  </Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}><TextField value={nomeCategoria} onChange={(v:any)=>{console.log(v.target.value);setNomeCategoria(v.target.value)}} id="standard-basic" label="Nome da Unidade" variant="standard" /></Typography>
                          {errorMessageText ? (
                            <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>{errorMessageText}</Typography>
                            ):null}
                        </Box>
                      ) : null
                    }
                    {
                      activeStep == 1 ? (
                        <Box sx={{   margin:15 }} style={{display: 'flex',flexDirection: 'column',height:50, alignContent: 'center', justifyContent: 'space-around', alignItems: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
                          
                          <Typography sx={{ mt: 2, mb: 1 }}>A unidade {nomeCategoria} foi criada com sucesso!</Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}>Utilize o Aplicativo e capture seu primeiro ítem, por QrCode ou Scanner RFID</Typography>
                        </Box>
                      ) : null
                    }
                    {
                      activeStep == 2 ? (
                        <Box sx={{   margin:15 }} style={{display: 'flex',flexDirection: 'column',height:50, alignContent: 'center', justifyContent: 'space-around', alignItems: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
                          <Typography sx={{ mt: 2, mb: 1 }}>Com ítens organizados em Unidades você já está pronto para fazer Usuários</Typography>
                          <Typography sx={{ mt: 2, mb: 1 }}>Utilize o Aplicativo e faça Usuários dos ítens registrados</Typography>
                        </Box>
                      ) : null
                    }
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        voltar
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Pular
                        </Button>
                      )}
                      <Button disabled={isLoading} onClick={handleNext}>
                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                      </Button>
                    </Box>
                    </React.Fragment>
                      )}
                    </Box>
                

                    </Paper> 
                  </Grid>
                </Grid>
              </Container>
                    
                  
                  
              
            ):(
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            
              <Grid container spacing={3}>
                {/* produtos */}
                <Grid item xs={6} md={4} lg={4} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <React.Fragment>
                      <Title>Aplicações</Title>
                      <Typography component="p" variant="h3" >
                        {aplicacoes}
                      </Typography>
                      <Typography color="text.secondary" sx={{ flex: 1 }}>
                        em {getDate()}
                      </Typography>
                      <div>
                        <Tooltip TransitionComponent={Zoom}   title="Aplicações" arrow>
                            <Button color="primary" onClick={()=>{navigate('/aplicacoes')}}>
                              Ver Aplicações
                            </Button>
                          </Tooltip>
                        
                      </div>
                    </React.Fragment>
                  </Paper>
                </Grid>
                <Grid item xs={6} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <React.Fragment>
                      <Title>Valor Investido</Title>
                      <Typography component="p" variant="h3" >
                        {investido}
                      </Typography>
                      <Typography color="text.secondary" sx={{ flex: 1 }}>
                        em {getDate()}
                      </Typography>
                      <div>
                        <Tooltip TransitionComponent={Zoom}   title="Investimentos" arrow>
                            <Button color="primary" onClick={()=>{navigate('/aplicacoes')}}>
                              Ver Investimentos
                            </Button>
                          </Tooltip>
                        
                      </div>
                    </React.Fragment>
                  </Paper>
                </Grid>
                {/* Chart */}
                <Grid item xs={6} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <React.Fragment>
                      <Title>Ofertas </Title>
                      <Typography component="p" variant="h3" >
                        {empreendimentos}
                      </Typography>
                      <Typography color="text.secondary" sx={{ flex: 1 }}>
                        em {getDate()}
                      </Typography>
                      <div>
                        <Tooltip TransitionComponent={Zoom}   title="Ofertas" arrow>
                          <Button color="primary" onClick={()=>{navigate('/ofertas')}}>
                          Ver ofertas</Button>
                        </Tooltip>
                        
                      </div>
                    </React.Fragment>
                    
                  </Paper>
                </Grid>
                
                <Grid item xs={6} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 400,
                    }}
                  >
                    <React.Fragment >
                      <Title>Usuários por invetimento </Title>
                      {
                        lista.length ? (
                      
                        <PieChart
                          series={[
                            {
                              data:lista,
                              highlightScope: { faded: 'global', highlighted: 'item' },
                              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            },
                          ]}
                          height={200}
                        />
                        ):null
                      }
                    </React.Fragment>
                  </Paper>
                  
                </Grid>
                
                <Grid item xs={6} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 400,
                    }}
                  >
                    <React.Fragment >
                      <Title>Valores por investimento</Title>
                      {
                        listaDuplo&&listaDuplo.length&&listaDuplo1&&listaDuplo1.length ? (
                      
                        <BarChart
                          dataset={listaDuplo}
                          xAxis={[{ scaleType: 'band', dataKey: 'nome',}]}
                          series={listaDuplo1}
                          {...chartSettingInv}
                        />
                        ):null
                      }
                    </React.Fragment>
                  </Paper>
                </Grid>
                <Grid item xs={12} >
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 400,
                    }}
                  >
                  {/*   <React.Fragment >
                      <Title>Resultados por unidade </Title>
                      {
                        lista1.length ? (
                          <div style={{alignSelf:'center'}}>
                            <PieChart 
                            /*  series={[
                                {
                                  data: lista1
                                },
                              ]} 
                              series={[
                                { data: lista1, outerRadius: 50 },
                                {
                                  data: lista2,
                                  innerRadius: 50,
                                  arcLabel: (params) => params.label ?? 'uau',
                                },
                              ]}
                              width={800}
                              height={300}
                            />
                          </div>
                          
                        ):null
                      }
                    </React.Fragment> */}
                  </Paper>
                </Grid>
                {/* Recent Deposits */}
              {/*  <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <Deposits />
                  </Paper>
                </Grid> */}
                {/* Recent Orders */}
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Orders />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                      <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
                  </Paper>
                </Grid>
              </Grid>
              
            </Container>
            )
          }
          
        </Box>
      </Box>
   
  );
}

const mapStateToProps = (state: any) => ({
    
    usuario: state.user.user, 
    
  });
  
  const mapDispatchToProps = {
    
    toggleDrawerStart,
    
  };
  
  const connector = connect(mapStateToProps, mapDispatchToProps);
  
  export default connector(Dashboard);