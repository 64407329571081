import React from 'react';
import ReactDOM from 'react-dom';
import * as V from 'victory';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';

import App from './App';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { DataGrid, ptBR } from '@mui/x-data-grid'

const theme = createTheme({
  
  palette: {
    primary: {
      // Purple and green play nicely together.
      //main: 'purple[500]',
      main: '#008800',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
  typography: {
    fontFamily: [
      'roboto',
      'serif',
    ].join(','),
  },
  
  
},ptBR);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
      <Helmet>
          <title>Plataforma LandBank</title>
        </Helmet>
        <ThemeProvider theme={theme}>
           <App />
        </ThemeProvider>
       
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
