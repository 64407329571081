//import * as React from 'react';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { getProdutos, getProdutosByCategory, updateProduto, setProdutoId,setProduto } from '../../redux/produtos/actions';
import { fetchEmpresas } from '../../redux/empresas/actions';
import { getCategorias } from '../../redux/categorias/actions';
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
// Generate Order Data
function createData(
  id: number,
  date: string,
  name: string,
  shipTo: string,
  paymentMethod: string,
  amount: number,
) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    '16 Mar, 2019',
    'Elvis Presley',
    'Tupelo, MS',
    'VISA ⠀•••• 3719',
    312.44,
  ),
  createData(
    1,
    '16 Mar, 2019',
    'Paul McCartney',
    'London, UK',
    'VISA ⠀•••• 2574',
    866.99,
  ),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(
    3,
    '16 Mar, 2019',
    'Michael Jackson',
    'Gary, IN',
    'AMEX ⠀•••• 2000',
    654.39,
  ),
  createData(
    4,
    '15 Mar, 2019',
    'Bruce Springsteen',
    'Long Branch, NJ',
    'VISA ⠀•••• 5919',
    212.79,
  ),
];




type PropsFromRedux = ConnectedProps<typeof connector>;

const Orders: React.FC<PropsFromRedux> = ({ categorias, getCategorias, empresas, fetchEmpresas, isLoadingProdutos, produtos, getProdutos, getProdutosByCategory}) => {
  useEffect(()=>{
    // Create the new date
    console.log(produtos)
    
    // abort request on unmount
    //return () => true;
  },[produtos])
const navigate = useNavigate()
  const goPage = ()=> {
    
    navigate('/produtos')
   // event.preventDefault();
  }

  const clearData = (d:any)=>{
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

  const getCategoriaNome =(id:any)=>{
    const item = categorias.filter((el:any)=> el._id == id)
    return item[0].nome

  }


  return (
    <React.Fragment>
      <Title>Movimentações recentes</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Unidade</TableCell>
            <TableCell align="right">Quantidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            produtos.length&&categorias.length?(
              produtos.map((row:any) => (
                <TableRow key={row.id}>
                  <TableCell>{clearData(row.createdAt)}</TableCell>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.tipo}</TableCell>
                  <TableCell>{getCategoriaNome(row.categoria)}</TableCell>
                  <TableCell align="right">{`${row.quantidade}`}</TableCell>
                </TableRow>
              ))
            ):null
          }
          
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={()=>{navigate('/produtos')}} sx={{ mt: 3 }}>
        Ver mais movimentações
      </Link>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => ({
  usuarios: state.usuarios.usuarios, 
  empresas: state.empresas.empresas,
  produtos: state.produtos.produtos,
  categorias: state.categorias.categorias,
  isLoadingUsuarios : state.usuarios.isLoadingUsuarios,
  isLoadingProdutos : state.produtos.isLoadingProdutos,
});

const mapDispatchToProps = {
  fetchEmpresas,
  getProdutos,
  getProdutosByCategory,
  getCategorias,
  
};

const connector = connect(mapStateToProps, mapDispatchToProps);
  
  export default connector(Orders);