import { initializeApp } from 'firebase/app';
import {collection, query, getFirestore, addDoc, doc, setDoc,getDoc, getDocs, where, getCountFromServer, getAggregateFromServer, sum, serverTimestamp } from "firebase/firestore";
import { getAuth, updateProfile } from 'firebase/auth'; // Importe outros módulos do Firebase que você está usando
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
    apiKey: 'AIzaSyCWD5VJQ9Cny1czX3I-rulZDOsFM3EFhmA',
    authDomain: "landbank-195b3.firebaseapp.com",
    projectId: "landbank-195b3",
    storageBucket: "landbank-195b3.appspot.com",
    messagingSenderId: "1008347786562",
    appId: "1:1008347786562:web:1bd1df8ede0f2dfc982cdb",
    measurementId: "G-MDSMP9WY31"
};




const initApp = initializeApp(firebaseConfig);
const auth = getAuth(initApp); // Inicialize o módulo de autenticação
const base:any = getFirestore(initApp);

/* const appCheck = initializeAppCheck(initApp, {
  provider: new ReCaptchaV3Provider('6LdbqJApAAAAAJt9OF7NNO-JUqPRCqCBzL8gKv-l'),
   // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
   isTokenAutoRefreshEnabled: true
}); */




const create = async (tabela:any, dados:object)=>{
  const result = await addDoc(collection(base, tabela), dados);
  return  result; 
}
const update = async (tabela:any, referencia:any, dados:object)=>{
            const data = {...dados, updatedAt: serverTimestamp()}
            const result = await setDoc(doc(base, tabela, referencia), 
              data
            );
            return  result; 
}

const updateProfileImg = async (imgUrl:any)=>{
  let user:any = auth.currentUser;
  const result = await updateProfile( user , { photoURL: imgUrl })
  console.log('result update  profile photoURL', result)
  return result
}

const find = async (tabela:any, id:any)=>{
  const finduser = await getDoc(doc(base, tabela, id))
  return finduser;
}
const findAll = async(tabela:any)=>{
  const querySnapshot = await getDocs(collection(base, tabela));
  console.log('querySnapshot',querySnapshot)
  const result:any = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    result.push({...doc.data(),_id:doc.id})
  });
  return result
}
const findQuery = async(tabela:any, key:any, value:any)=>{
  try{
    const q = query(collection(base, tabela), where(key, "==", value));

  const querySnapshot = await getDocs(q);
  console.log('query', querySnapshot)
  const arr:any = []
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    arr.push({_id:doc.id,   ...doc.data()});
  });
  return  arr;
  }catch(error:any){
    console.log('findQuery',error)

  }
  
}
const logout = ()=>{

}
const makeCount = async (tabela:any, field:any, value:any)=>{
  const coll = collection(base, tabela);
  const q = query(coll, where(field, "==", value));
  const snapshot = await getCountFromServer(q);
  console.log('count: ', snapshot.data().count);
  return snapshot.data().count
}
const makeSum = async (tabela:any, field:any, value:any,itemSum:any)=>{

  console.log('tabela', tabela)
  console.log('field', field)
  console.log('value', value)
  console.log('itemSum', itemSum)
  const coll = collection(base, tabela);
  const q = query(coll, where(field, '==', value), where("ativo", '==', true));
  const snapshot = await getAggregateFromServer(q, {
    total: sum(itemSum)
  });

  console.log('total: ', snapshot.data().total);
  return  snapshot.data().total

}
export { initApp, auth, create, update, updateProfileImg, find, findAll, findQuery, makeCount, makeSum, logout }; // Exporte os módulos que você deseja usar em outras partes do seu aplicativo
