import React, { useState, useEffect } from 'react';
import { MapStateToProps, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Avatar, Menu, MenuItem, ListItemButton } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
//import Toolbar from '@mui/material/Toolbar';
import { RootState } from './../../redux/store';
import { connect, ConnectedProps } from 'react-redux';

import { useNavigate } from 'react-router-dom'
import './Header.css';
import logo from './../../assets/images/logo.jpg'
import { motion } from "framer-motion";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/icons-material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';
import List from '@material-ui/core/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Money from '@mui/icons-material/Money';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {logout} from './../../redux/auth/actions'
import {fetchEmpresaById} from './../../redux/empresas/actions'
import {setImageProfile, getImagesByUser} from './../../redux/usuarios/actions'
import {toggleDrawerStart} from './../../redux/global/actions'
//import { MainListItems, SecondaryListItems, HeaderListItems } from './../Elements/listItems';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { serverConfig } from './../../api/apiConfig';


type PropsFromRedux = ConnectedProps<typeof connector>;

const Header: React.FC<PropsFromRedux> = ({isLogged, user,imagesByUser, getImagesByUser, empresa, empresas, fetchEmpresaById, setImageProfile, logout,drawer}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const user = useSelector((state: RootState) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [role, setRole] = useState<any>({admin:false});
 // const [open, setOpen] = useState(false);
  const [empresaName, setEmpresaName] = useState('Greentag');


  const toggleDrawer = () => {
   // setOpen(!open);
   dispatch( toggleDrawerStart())
  };

  

  useEffect(()=>{
    if(empresa?.nome){
      const name = empresa.nome
      setEmpresaName(name)
    }
    
  },[empresa])

  useEffect(() => {
    console.log('user header ===>>>' , user)
    if(user?.permissoes){
      setRole(user.permissoes);
      //fetchEmpresaById(user.empresa)
      //getImagesByUser(user._id)
    }
    
  }, [user]);

  useEffect(() => {
    //setImageProfile('')
    console.log("imagesByUser", imagesByUser)
    const imagesList:any = [];
    let cont = 0
    imagesByUser.map((item:any, index:any)=>{
      cont = (cont < item.contador ? item.contador : cont)
      const slide = {
        key: index,
        title: 'Usuario',
        text: '',
        image:serverConfig.dev.url + 'imagens/imagem/'+item._id,
        backgroundColor: 'green',
        
      }
      imagesList.push(slide)
    })
    console.log('imagesList ==>>> ', imagesList)
    console.log('cont ==>>> ', cont)
    
    if(imagesList.length){
      const image = imagesList[0].image
      //setImageProfile(image)
    }
    //setSlides(imagesList)
   
  },[imagesByUser]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleProfileMenuClose()
    logout()
    navigate('/login');
    // Implemente a lógica para fazer logout do usuário
  };
  const handleProfile = () => {
    handleProfileMenuClose()
    navigate('/usuario');
    // Implemente a lógica para fazer logout do usuário
  };
  const handleCarteira = () => {
    handleProfileMenuClose()
    navigate('/carteira');
    // Implemente a lógica para fazer logout do usuário
  };

  const goHome = ()=>{
    if(isLogged)
      toggleDrawer()
    //navigate('/')
  }

  const getEmpresaNome = {

  }

  return (
    <>
      <AppBar position="static">
      <Toolbar className='header'>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className='typography' onClick={goHome}>
          <img src={logo} className='logo'  />
          <motion.div
            className="box"
            initial={{ opacity: 1 }}
            animate={{ opacity: .4 , left: 260}}
            transition={{ duration: 2 }} // Duração da animação em segundos
          />
        </Typography>
        

        <div>
          
          {/* {role === 'admin' && (
            <Button color="inherit" component={Link} to="/teacher-menu">
              Menu do Administrador
            </Button>
          )} */}

          {
            isLogged ? (
              <Button color="inherit" onClick={handleProfileMenuOpen}>
            
                <span className="user-info">
                  <span className="user-letter">
                  
                  <Avatar sx={{ width: 50, height: 50 }} alt={user.nome} src={user.photoURL || user.avatar} />
                  </span>
                  <span className="user-detail">
                    <span className="user-name">{user.nome}</span>
                    <span className="user-role">{user.email}</span>
                  </span>
                </span>
              </Button>
            ) : null
          }

          
          <Menu
            
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileMenuClose}
          >
            <MenuItem style={{}}  onClick={handleCarteira}>Carteira</MenuItem>
            <MenuItem style={{}}  onClick={handleProfile}>Gerenciar Perfil</MenuItem>
            <MenuItem style={{}} onClick={handleLogout}>Sair</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
    <Drawer variant="persistent" open={drawer}>
    <Toolbar
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
        }}
      >
      <IconButton onClick={toggleDrawer}>
        <ChevronLeftIcon />
      </IconButton>
    </Toolbar>
    <Divider />
    <List component="nav" style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        flexDirection:'column',
        margin:10
        }} >
    <React.Fragment>
    <ListItemButton style={{width:'100%'}} >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" onClick={()=>{navigate('/dashboard');toggleDrawer();}} />
    </ListItemButton>
    
    <ListItemButton style={{width:'100%'}}>
      <ListItemIcon>
        <Money />
      </ListItemIcon>
      <ListItemText primary="Ofertas"  onClick={()=>{navigate('/ofertas');toggleDrawer();}} />
    </ListItemButton>
    
    <ListItemButton style={{width:'100%'}}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Perfil"  onClick={()=>{navigate('/usuario');toggleDrawer();}} />
    </ListItemButton>
    <ListItemButton style={{width:'100%'}} >
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Meus Investimentos"  onClick={()=>{navigate('/aplicacoes');toggleDrawer();}}/>
    </ListItemButton>
    
    {/* <ListItemButton style={{width:'100%'}}>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton> */}
    </React.Fragment>

   {/*  {role?.manager||role?.admin ? (
      <React.Fragment>
        <Divider sx={{ my: 1 }} />
        <ListSubheader component="div" inset>
          Administrador
        </ListSubheader>
        <ListItemButton style={{width:'100%'}}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Home" onClick={()=>{navigate('/');toggleDrawer();}} />
        </ListItemButton>
        <ListItemButton style={{width:'100%'}}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Perfil da Empresa" onClick={()=>{navigate('/empresa');toggleDrawer();}} />
        </ListItemButton>
        

        
        
      </React.Fragment>
    ):null} */}
    
    {role?.admin ? (
    
      <React.Fragment>
        <Divider sx={{ my: 1 }} />
        <ListSubheader component="div" inset>
          Administrador Master
        </ListSubheader>
        
        <ListItemButton style={{width:'100%'}}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Empresas" onClick={()=>{navigate('/empresas');toggleDrawer();}} />
        </ListItemButton>

        <ListItemButton style={{width:'100%'}}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Usuários" onClick={()=>{navigate('/usuarios');toggleDrawer();}} />
        </ListItemButton>
       {/*  <ListItemButton style={{width:'100%'}}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="Localizar Usuários" onClick={()=>{navigate('/location');toggleDrawer();}} />
        </ListItemButton> */}
        
      </React.Fragment>
    ) : null}
      
          
      <Divider sx={{ my: 1 }} />
      <ListItemButton style={{width:'100%'}} >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Sair" onClick={()=>{
        toggleDrawer();
        navigate('/login');
      }
        } />
    </ListItemButton>
    </List>
  </Drawer>
    </>
    
  );
};
const mapStateToProps = (state: RootState) => ({
  user: state.user.user, isLogged : state.auth.isLogged , // Ajuste aqui para a propriedade correta do estado do usuário
  drawer:state.global.drawer,
  empresa:state.empresas.empresa,
  empresas:state.empresas.empresas,
  
  imagesByUser:state.usuarios.imagesByUser,
});



const mapDispatchToProps = {
  logout,
  fetchEmpresaById,
  setImageProfile,
  getImagesByUser

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Header);

