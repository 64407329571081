import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal, LinearProgress, FormGroup, FormControlLabel, Checkbox, ListItemText, OutlinedInput } from '@material-ui/core';
import { InputLabel, Select, MenuItem, InputAdornment } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { fetchEmpresas } from './../redux/empresas/actions'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoneyIcon from '@material-ui/icons/Money';
import { Snackbar } from '@mui/base/Snackbar';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { green } from '@material-ui/core/colors';
import { Email, Phone, Business, ViewHeadline } from '@material-ui/icons';
import { width } from '@mui/system';
import { Avatar, Collapse, dividerClasses } from '@mui/material';

import Notifications from './../components/notifications/Notifications';
import { Autocomplete, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, Link, ListItemButton, ListSubheader, Stack, styled } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { serverTimestamp } from 'firebase/firestore'
import { auth, initApp, create, update, find, findAll, findQuery, makeSum } from './../firebase';


import QRCode from 'qrcode.react';
import { donationPost } from './../api/donationPost';
import { donationGet, Donation } from './../api/donationGet';
import { serverConfig } from './../api/apiConfig';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',

  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
    width: '450px'
  },
  content: {
    textAlign: 'center',
  },

  listContainer: {
    width: '100%',
    minWidth: '700px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'space-around',

    alignItems: 'flex-start',

    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },

  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: 300,
    minHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 500
  },
  indeterminateColor: {
    color: "green"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const OfertasPage: React.FC<PropsFromRedux> = ({ usuario }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);

  const [selectedCategoria, setSelectedCategoria] = React.useState('');
  const [selectedNewCategoria, setSelectedNewCategoria] = React.useState('');
  const [addCategoriaModalOpen, setAddCategoriaModalOpen] = React.useState(false);
  const [newCategoriaName, setNewCategoriaName] = React.useState('');
  const [newCategoriaEmpresa, setNewCategoriaEmpresa] = React.useState('');
  const [editCategoriaData, setEditCategoriaData] = React.useState<any>({});
  const [ofertasList, setofertasList] = React.useState<any>({});
  const [produtosList, setProdutosList] = React.useState<any>({});
  const [editCategoriaModalOpen, setEditCategoriaModalOpen] = React.useState(false);
  const [productsModalOpen, setProductsModalOpen] = React.useState(false);
  const [newUnidadeVisible, setNewUnidadeVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [ofertas, setOfertas] = React.useState<any[]>([]);
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);
  const [categoriasToShow, setCategoriasToShow] = React.useState([{ nome: 'Todas', _id: 0 }]);
  const [errorMessageLocal, setErrorMessageLocal] = React.useState('');
  const [errorMessageText, setErrorMessageText] = React.useState<any>('');

  const [sending, setSending] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [itensSendList, setItensSendList] = React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [donation, setDonation] = React.useState<any>(null);
  const [saldo, setSaldo] = React.useState<any>(0);
  const [comment, setComment] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);
  const [carteiraList, setCarteiraList] = React.useState<any>(null);

  const apiRef = useGridApiRef();

  const [expanded, setExpanded] = React.useState<any>('');
  const handleExpandClick = (id: string) => {
    const newValue = expanded == id ? '' : id
    setExpanded(newValue);
  };

  const handleChange = (event: any) => {
    const valor: any = isAllSelected ? false : true;
    console.log(valor)
    setIsAllSelected(valor)
  };
  useEffect(() => {
    if (save) {
      //handleUpdateCategoria()
      setSave(false)
    }
    console.log('rows', rows)
  }, [rows]);
  useEffect(() => {

    setErrorMessageText('')

  }, [])

  const getEmpreendimentos = async () => {
    const valor: any = await findAll('empreendimentos')
    console.log('getEmpreendimentos', valor)
    setOfertas(valor)
    setLoading(false)
    getMovimentacoesCarteira()
  }


  const makeSaldo = async () => {
    const soma = await makeSum("carteira", "usuario", usuario.id, "valor")
    setSaldo(soma)
  }
  const getMovimentacoesCarteira = async () => {

    const valor: any = await findQuery('carteira', 'usuario', usuario.id)
    console.log('valor carteira====>', valor)
    setCarteiraList(valor)
    //setRows(valor);
    setLoading(false)
    makeSaldo()
  }

  useEffect(() => {
    setLoading(true)
    getEmpreendimentos()
  }, []);



  useEffect(() => {
    console.log('selected', selected)
  }, [selected]);
  useEffect(() => {

    if (ofertas.length) {
      const order = (a: any, b: any) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      const orderedCategorias = ofertas.slice().sort(order)
      console.log('orderedCategorias', orderedCategorias)
      setCategoriasToShow(orderedCategorias)
      setRows(orderedCategorias)
      setofertasList(orderedCategorias)
    }
  }, [ofertas]);


  const createDonation = async (value: any, comment: any) => {
    const payload = {
      comment,
      value, // always 1 cent per now
    }
    const result: any = await donationPost(payload);
    console.log({
      result,
    });
    if (result.error) {
      setError(result.error);
      return;
    }
    setDonation(result);
    return result;
  }

  const checkStatus = async () => {
    const result: any = await donationGet(donation.id);
    console.log(
      'donation result', result
    );
    if (result.error) {
      setError(result.error);
      return;
    }

    setDonation(result);
  }

  console.log({
    donation,
  });


  const statusFilter = (st: any) => {
    if (st === 'ACTIVE' || st == 'OPEN') {
      return 'Aguardando pagamento'
    } else if (st == 'COMPLETED') {
      return 'Transação concluída'
    }
    return st
  }

  const renderContent = () => {
    if (!donation) {
      return (
        <Box flex={1} mt='20px' ml='20px'>
          {/* <TextField label='Comment' value={comment} onChange={e => setComment(e.target.value)}/>
          <Button onClick={createDonation}>
            Donate
          </Button> */}
          {!!error && (<div>{error.toString()}</div>)}
        </Box>
      );
    }

    return (
      <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'center', paddingBottom: 10, borderColor: 'black', borderWidth: 2 }}>
        <div>Transferir com Pix</div>
        <div>Código:</div>
        <div style={{ width: '100%', overflowX: 'hidden' }}> {donation.brCode}</div>

        {
          donation.status === 'COMPLETED' ? (
            <div style={{ color: 'green' }}>Transação concluída com sucesso!</div>

          ) : (
            <>
              <div style={{ color: 'red' }}>{statusFilter(donation.status)}</div>
              <QRCode
                size={180}
                renderAs={'svg'}
                value={donation.brCode}
                includeMargin={false}
              />

              <Button onClick={checkStatus}>
                Validar pagamento
              </Button>
            </>
          )
        }


      </Box>
    )
  }





  const handleDeleteClick = (id: any) => {
    console.log('categoria ==>> ', id)
    setSelectedCategoria(id);
    setDeleteConfirmationOpen(true);
  };

  const updateCarteira = async (carteiraId: any, dados: any) => {
    console.log("dados para update", dados)
    await update("carteira", carteiraId, dados)
    //getMovimentacoesCarteira()

    return;
  }

  const handleInvestir = async () => {
    setLoading(true)
    const valor = (Number(editCategoriaData.valor) || 500)
    let soma = 0
    console.log("saldo", saldo)

    if (saldo < valor) {
      setErrorMessageText("Saldo insuficiente")
      setLoading(false)
      return;
    }
    const transacaoList: any = []
    console.log('valor',valor)
    for (let i = 0; i < carteiraList.length; i++) {
      const item = carteiraList[i]
      const val = item.valor
      const utilizado = item.utilizado || 0
      let valorUtilizado = 0
      if (soma < valor) {
        const data = {...item}
        if (soma + val <= valor) { 
          valorUtilizado = val
          soma += val 
          data.utilizado = val + utilizado
        }
        else {
          const val1 = valor - soma
          
          if (soma + val1 <= valor) { 
            valorUtilizado = val1
            soma += val1 
            data.utilizado = val1 + utilizado
          }
        }
        data.valor = val - valorUtilizado
        transacaoList.push({carteiraId:item._id ,valorUtilizado })
        console.log('soma',soma)
        console.log('data',data)

        await updateCarteira(item._id, data)
      }
      
    }
    console.log('transacaoList',transacaoList)
    //return
    
    const oferta = { ...editCategoriaData }

    const valorPix = (Number(editCategoriaData.valor) || 500) * 100
    const tempo = editCategoriaData.tempo
    delete oferta.valor
    console.log(usuario)
    console.log('valor', valor)

    const dados = {
      oferta,
      valor,
      tempo,
      transacao: transacaoList,
      'usuario': usuario.id,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    }
    const registro = await create('investimentos', dados)
    setLoading(false)
    console.log(registro)
    navigate('/aplicacoes')
  };

  const handleDeleteConfirmation = () => {

    setDeleteConfirmationOpen(false);
  };

  const handleAddCategoria = () => {
    const novaCategoria = {
      nome: newCategoriaName,
      empresa: newCategoriaEmpresa,
      usuario: usuario._id

    };


    setNewCategoriaName('');
    setNewCategoriaEmpresa('');

  };



  const handleEditCategoria = (categoria: any) => {
    console.log('categoria selecionada ==>> ', categoria)
    setEditCategoriaData(categoria);
    setEditCategoriaModalOpen(true);
  };
  const handleOferta = (oferta: any) => {
    console.log('oferta selecionada ==>> ', oferta)
    oferta.valor = 500
    oferta.tempo = 1
    setEditCategoriaData(oferta);
    setSelectedNewCategoria('')
    setProdutosList([])
    setSelected([])
    setErrorMessageLocal('')
    setSelectedIds([])
    setProductsModalOpen(true)
    setShowMessage(false)

  };





  const handleConcluir = () => {

    setIndex(0)
    console.log('itensList', itensSendList)
    setSending(true)
    sendNext(0)
  }


  const sendNext = (next: any) => {
    console.log('next', next)
    const item = itensSendList[next]
    console.log(item)

  }

  const getCategoriaNome = (id: any) => {
    const item = ofertas.filter((el: any) => el._id == id)
    return item[0].nome

  }


  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row: any) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row: any) => row._id !== id));
    }
  };

  const processRowUpdate: any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow', newRow)
    if (newRow.isNew) {
      delete newRow.isNew
    }
    setEditCategoriaData(newRow)
    setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type: 'Date',
      width: 140,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => clearData(params.row.createdAt)
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'rendimento',
      headerName: 'Rendimento',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.rendimento + '% a.a.'
    },




    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const oferta = ofertasList.filter((item: any) => item._id == id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [

          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom} title="Investir" arrow>
              <ViewHeadline className='iconDetail' />
            </Tooltip>}
            label="Investir nesta aplicação"
            className="textPrimary"
            onClick={() => handleOferta(oferta)}
            color="inherit"
          />,
          /*<GridActionsCellItem
            icon={ <Tooltip TransitionComponent={Zoom}   title="Detalhes" arrow><MoneyIcon className='iconDetail' /></Tooltip>}
            label="Detalhes"
            className="textPrimary"
            onClick={()=>handleOferta(oferta)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={ <Tooltip TransitionComponent={Zoom}   title="Editar" arrow><EditIcon className='iconDetail' /></Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
           <GridActionsCellItem
          
            icon={ <Tooltip TransitionComponent={Zoom}   title="Excluir" arrow><DeleteIcon className='iconDetail' /></Tooltip>}
            label="Delete"
            onClick={()=>{
              handleDeleteClick(id)
            }}
            color="inherit"
          />, */

        ];
      },
    },


  ];

  const hiddenFields = ['_id', 'id', 'ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns', columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  interface AutocompleteOption {
    categoria: string;
  }

  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

  const changeFilter = () => {

    const objToSend = {
      //startDate,
      //endDate,
      empresa: usuario.empresa,
      //categoria:selectedCategory?._id || '',
      usuario: selectedUser?._id || '',
      //produto : selectedProduto?._id || '',
    }
    console.log('objToSend', objToSend)
    //getInventoriesByFilter(objToSend)

  }

  const handleSaveClick = (id: GridRowId) => () => {
    const el = rows.filter((item: any) => item._id == id)
    console.log('handleSaveClick', id)
    console.log('handleSaveClick', el)
    setSave(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };



  function acceptRequest(item: any): void {
    throw new Error('Function not implemented.');
  }

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.content}>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography variant="h4" className={'title'}>
            Ofertas disponíveis
          </Typography>
          {/* <Button className={classes.button} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddCategoriaModalOpen(true)}>
            Adicionar

          </Button> */}

          <Box sx={{ width: '100%', alignItems: "center" }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignSelf: 'center', paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
            {ofertas && ofertas.length ? (
              <div className={classes.listContainer}>
                {ofertas.map((item: any) => (
                  <Card sx={{ maxWidth: 345 }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: '#0df228' }} aria-label="recipe">
                          {item.nome.substring(0, 2)}
                        </Avatar>
                      }
                      action={
                        <IconButton aria-label="settings">
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={item.nome}
                      subheader={clearData(item.createdAt)}
                    />
                    <CardMedia
                      onClick={() => {
                        handleOferta(item)
                      }}

                      width={150}
                      component="img"
                      height="150"
                      image={item.image ? item.image : "https://landbankinvestimentos.com.br/v2/assets/images/oferta1.jpg"}
                      alt="scout image"
                    />
                    <CardContent>
                      <Typography variant="body2" color="primary">
                        Rendimento {item.rendimento}% a.a
                        {/*    <Link href={`${item.link}`} target="_blank" underline="hover">
                          {'Job link'}
                        </Link> */}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      {/* <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                  </IconButton> */}

                      <Button className='{classes.irButton}' onClick={() => {
                      }}>+ info</Button>

                      <ExpandMore
                        expand={expanded == item._id ? true : false}
                        onClick={() => { handleExpandClick(item._id) }}
                        aria-expanded={expanded == item._id}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded == item._id} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Button className='{classes.irButton}' onClick={() => handleOferta(item)}>Investir</Button>
                      </CardContent>
                    </Collapse>
                  </Card>
                )

                )}


              </div>
            ) : (<div style={{ margin: 15, color: "whitesmoke" }}>
              Buscando ofertas
              {/* <Tooltip TransitionComponent={Zoom} title="Clear" arrow>
                <IconButton onClick={()=>clearSelected()} size='small' className='iconDetail' color="primary" aria-label="Editar" >
                  <Close />
                </IconButton>
              </Tooltip> */}
            </div>)}
          </Box>


          {/* 
          {rows.length ? (
            <Box sx={{ width: '100%' }}>

              <DataGrid
                apiRef={apiRef}
                loading={loading}
                getRowId={(row: { _id: any; }) => row._id}
                //checkboxSelection
                disableRowSelectionOnClick

                onClipboardCopy={(copiedString) => console.log(copiedString)}
                unstable_ignoreValueFormatterDuringExport
                autoHeight={true}
                rows={rows}
                columns={columns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'createdAt', sort: 'desc' }],
                  },

                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                //checkboxSelection

                //editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                // slots={{
                //   toolbar: EditToolbar,
                // }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    setRows, setRowModesModel, csvOptions: {
                      fileName: 'unidadesDataBase_',
                      delimiter: ';',
                      utf8WithBom: true,
                    },
                    columns: {
                      columnVisibilityModel: { ID: false, },
                    },
                    columnsPanel: {
                      getTogglableColumns,
                    },
                    printOptions: { hideToolbar: true, disableToolbarButton: false }
                  },
                }}



              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },


              />

            </Box>
          ) : (<div style={{ margin: 15 }}>
            Nehum registro encontrado
          </div>)} */}


          {/* {ofertasList.length ? (
            <div className='listContainer'>
              <List>
                {ofertasList.map((categoria: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <Typography className='text'>{categoria.nome}</Typography>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'space-evenly', }}>
                      

                      <Tooltip TransitionComponent={Zoom}   title="Transferir" arrow>
                        <IconButton color="primary" aria-label="Ver ítens" onClick={() => handleOferta(categoria)}>
                          <Typography  className='text'>{'ver itens'}</Typography>
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Editar" arrow>
                        <IconButton color="primary" aria-label="Editar" onClick={() => handleEditCategoria(categoria)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
                        <IconButton
                          color="secondary"
                          aria-label="Deletar"
                          className={classes.deleteButton}
                          onClick={() => handleDeleteClick(categoria._id)}
                        >                        
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addCategoriaModalOpen} onClose={() => setAddCategoriaModalOpen(false)}>
        <DialogTitle>Adicionar</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Unidade"
            value={newCategoriaName}
            onChange={(e) => setNewCategoriaName(e.target.value)}
          />


          {errorMessageText ? (
            <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>{errorMessageText}</Typography>
          ) : null}



        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            //clearErrorMessage()
            setAddCategoriaModalOpen(false)
          }}>Cancelar</Button>
          <Button onClick={handleAddCategoria} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog open={productsModalOpen} style={{ minWidth: 600, minHeight: 500, color: '#9c2726' }} onClose={() => {
        setProductsModalOpen(false)
        setNewUnidadeVisible(false)
      }}>
        <DialogTitle>Produto:  {editCategoriaData.nome}</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <div >
            rendimento: <span style={{ fontWeight: 'bold' }}>{editCategoriaData.rendimento + '% a.a.'}</span>
          </div>
          <Typography color={'black'} sx={{ mt: 2, mb: 1 }}>Saldo em carteira: R$ {saldo},00</Typography>
          {errorMessageText ? (
            <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>{errorMessageText}</Typography>
          ) : null}
          <div>
            valor a ser aplicado: <span style={{ fontWeight: 'bold' }}>R$ {editCategoriaData.valor},00</span>
          </div>


          <Box>
            <FormGroup >

              <input
                style={{
                  padding: '10px',
                  fontSize: '16px',
                  color: '#9c2726'
                }}
                type="number"
                min={500}
                step={500}
                value={editCategoriaData.valor}
                onChange={(e) => setEditCategoriaData({ ...editCategoriaData, valor: e.target.value })}
              />
              <Typography color={'black'} sx={{ mt: 2, mb: 1 }}>Tempo em anos</Typography>
              <input

                style={{
                  padding: '10px',
                  fontSize: '16px',
                  color: '#9c2726'
                }}
                type="number"
                min={1}
                step={10}
                value={editCategoriaData.tempo}
                onChange={(e) => setEditCategoriaData({ ...editCategoriaData, tempo: e.target.value })}
              />
              <FormControlLabel control={

                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  value={'all'}
                  indeterminate={
                    isAllSelected
                  }
                  onChange={handleChange}
                />} label="Confirmo que li e concordo com os termos da oferta" />

            </FormGroup>

          </Box>

          <>


          </>



          {/* <Button className='text' onClick={handleConcluir}>Contratar</Button> */}
        </DialogContent>
        <DialogActions>

          {
            !newUnidadeVisible ? (
              <Button disabled={!isAllSelected} onClick={() => {
                handleInvestir()
              }}>Investir</Button>
            ) : null
          }

          <Button onClick={() => {
            setProductsModalOpen(false)
            setNewUnidadeVisible(false)
          }}>Fechar</Button>
          {/* <Button onClick={handleUpdateCategoria} color="primary">
            Atualizar
          </Button> */}
        </DialogActions>
      </Dialog>


      {/* <Modal open={editCategoriaModalOpen} onClose={() => setEditCategoriaModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editCategoriaData.nome}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.email}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, email: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.empresa}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, empresa: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.cnpj}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateCategoria}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user.user,

});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(OfertasPage);
