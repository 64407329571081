import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal, LinearProgress, FormGroup, FormControlLabel, Checkbox, ListItemText, OutlinedInput } from '@material-ui/core';
import {  InputLabel, Select, MenuItem, InputAdornment } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { fetchEmpresas } from './../redux/empresas/actions'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { green } from '@material-ui/core/colors';
import { Email, Phone, Business, ViewHeadline } from '@material-ui/icons';
import { width } from '@mui/system';
import { dividerClasses } from '@mui/material';
import { Autocomplete, Divider, ListItemButton, ListSubheader, Stack } from '@mui/material';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import {auth, initApp, update, find, findAll, findQuery} from './../firebase';
import { donationGet } from './../api/donationGet';
import { Details } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
    
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
    width: '450px'
  },
  content: {
    textAlign: 'center',
  },
  
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor:'#9c2726',
    color: 'white',
    '&:hover': {
      backgroundColor: '#d5541b',
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth:300,
    minHeight:200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 500
  },
  indeterminateColor: {
    color: "green"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const AplicacoesPage: React.FC<PropsFromRedux> = ({ usuario}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedCategoria, setSelectedCategoria] = React.useState('');
  const [selectedNewCategoria, setSelectedNewCategoria] = React.useState('');
  const [addCategoriaModalOpen, setAddCategoriaModalOpen] = React.useState(false);
  const [newCategoriaName, setNewCategoriaName] = React.useState('');
  const [newCategoriaEmpresa, setNewCategoriaEmpresa] = React.useState('');
  const [editCategoriaData, setEditCategoriaData] = React.useState<any>({});
  const [aplicacoesList, setaplicacoesList] = React.useState<any>({});
  const [produtosList, setProdutosList] = React.useState<any>({});
  const [editCategoriaModalOpen, setEditCategoriaModalOpen] = React.useState(false);
  const [productsModalOpen, setProductsModalOpen] = React.useState(false);
  const [newUnidadeVisible, setNewUnidadeVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [aplicacoes, setaplicacoes] = React.useState<any[]>([]);
  const isAllSelected =
  produtosList.length > 0 && selected.length === produtosList.length;
  const [categoriasToShow, setCategoriasToShow] = React.useState([{nome:'Todas',_id:0}]);
  const [errorMessageLocal, setErrorMessageLocal] = React.useState('');
  const [errorMessageText, setErrorMessageText] = React.useState<any>('');

  const [sending, setSending] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [itensSendList, setItensSendList] =React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [donation, setDonation] = React.useState<any>(null);
  const [comment, setComment] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);
  const apiRef = useGridApiRef();

const handleChange = (event:any) => {
  const value = event.target.value;
  const checked  = event.target.checked
  console.log('value  ===== ', value)
  console.log('checked  ===== ', checked)
  if (value === "all") {
    const list = []
    const idsList = []
    for(let i=0 ; i < produtosList.length ; i++){
      list.push(produtosList[i].nome)
      idsList.push(produtosList[i]._id)

    }
    setSelected(selected.length === produtosList.length ? [] : list);
    setSelectedIds(selected.length === produtosList.length ? [] : idsList);
    //setSelectedIds( idsList);
    return;
  }
  if (checked)
    setSelected([value, ...selected]);
  else {
    let list = [...selected]
    list = list.filter((item)=> item != value)
    setSelected([...list]);
  }
};
useEffect(() => {
  if(save){
    //handleUpdateCategoria()
    setSave(false)
  }
  console.log('rows' , rows)
}, [rows]); 
useEffect(() => {
  
  setErrorMessageText('')

},[])


useEffect(() => {
 const getAplicacoes  =async ()=>{
    console.log(usuario)
    if(usuario.id)
    {
        const valor:any = await findQuery('investimentos', 'usuario', usuario.id)
        console.log('valor ==> ',valor)
        setaplicacoes(valor)
        setLoading(false)       
    }
 }
 getAplicacoes()
}, []);

  
  
  useEffect(() => {
    console.log('selected', selected)
  }, [selected]);
  useEffect(() => {

    if(aplicacoes.length){
      const order = (a:any,b:any)=>{
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      const orderedCategorias = aplicacoes.slice().sort(order)
      console.log('orderedCategorias', orderedCategorias)
      setCategoriasToShow(orderedCategorias)
      setRows(orderedCategorias)
      setaplicacoesList(orderedCategorias)
    }
  }, [aplicacoes]);

  const checkStatus = async (id:any, newRow:any) => {
    console.log("donationId", id)
    const result: any = await donationGet(id);
    /* console.log(
      'donation result', result
    ); */
    if (result.error) {
      setError(result.error);
      return;
    }

    const updatedRow = {...newRow }
    updatedRow.transacao.status = result.status
    console.log('newRow._id', newRow._id)
    console.log('updatedRow', updatedRow)
    
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    
    return result
    //setDonation(result);
  }

  const statusFilter = (st:any)=>{
    console.log(st)
    if(st === 'ACTIVE' || st=='OPEN'){
      return 'Aguardando pagamento'
    }else if(st=='EXPIRED'){
      return 'Pix expirado'
    }else if(st=='COMPLETED'){
      return 'Transação concluída'
    }
    console.log('statusFilter',st)
    return st
  }

  

  const handleDeleteClick = (id: any) => {
    console.log('categoria ==>> ',id)
    setSelectedCategoria(id);
    setDeleteConfirmationOpen(true);
  };

  const handleSelectCategoria = () => {
    
    setNewUnidadeVisible(true)
  };

  const handleDeleteConfirmation = () => {
    
    setDeleteConfirmationOpen(false);
  };

  const handleAddCategoria = () => {
    const novaCategoria = {
      nome: newCategoriaName,
      empresa: newCategoriaEmpresa,
      usuario : usuario._id
      
    };
    
    
    setNewCategoriaName('');
    setNewCategoriaEmpresa('');

  };
  


  const handleEditCategoria = (categoria:any) => {
    console.log('categoria selecionada ==>> ', categoria)
    setEditCategoriaData(categoria);
    setEditCategoriaModalOpen(true);
  };
  const handleProdutosbyCategoria = (categoria:any) => {
    console.log('categoria selecionada ==>> ', categoria)
    setEditCategoriaData(categoria);
    setSelectedNewCategoria('')
    setProdutosList([])
    setSelected([])
    setErrorMessageLocal('')
    setSelectedIds([])
    setProductsModalOpen(true)
    setShowMessage(false)
    
  };
  
  

  
  
  const handleConcluir = ()=>{
    
    setIndex(0)
    console.log('itensList',itensSendList)
    setSending(true)
    sendNext(0)
  }


  const sendNext = (next:any) =>{
    console.log('next',next)
    const item = itensSendList[next]
    console.log(item)
    
  }

  const getCategoriaNome =(id:any)=>{
    const item = aplicacoes.filter((el:any)=> el._id == id)
    return item[0].nome

  }


  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    ///setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    if(newRow.isNew){
      delete newRow.isNew
    }
    setEditCategoriaData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const formatCurrency =  (value: number): string=> {
    const formattedValue = value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    return formattedValue;
  }
 
  // Calcula o montante final com base na taxa de juros anual e tempo decorrido
function calcularMontante(valorInvestido: number, taxaJurosAnual: number, dataAplicacao: Date) {
    // Convertendo a taxa de juros para decimal
    console.log(valorInvestido)
    console.log('taxaJurosAnual',taxaJurosAnual)
    const taxaJurosDecimal = taxaJurosAnual / 100;

    // Obtendo a data atual
    const dataAtual = new Date();
  console.log('dataAtual',dataAtual)
  console.log('dataAplicacao',dataAplicacao)
    // Calculando o tempo decorrido em meses
    const tempoEmMeses = (dataAtual.getFullYear() - dataAplicacao.getFullYear()) * 12 +
        (dataAtual.getMonth() - dataAplicacao.getMonth());
    console.log('tempoEmMeses',tempoEmMeses)
    // Calculando o montante final
    const montanteFinal = valorInvestido * Math.pow(1 + taxaJurosDecimal, tempoEmMeses / 12);
    console.log('montanteFinal',montanteFinal)
    const valorReal = formatCurrency(Number(montanteFinal.toFixed(2)))
    return valorReal;
}

// Exemplo de uso:
/* const valorInvestido = 1000; // Valor inicial investido
const taxaJurosAnual = 12; // Taxa de juros anual (12%)
const dataAplicacao = new Date('2023-01-15'); // Data de aplicação (exemplo) */

/* const montanteAtualizado = calcularMontante(valorInvestido, taxaJurosAnual, dataAplicacao);
console.log(`O montante atualizado após ${tempoEmMeses} meses é de R$${montanteAtualizado.toFixed(2)}`); */


  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 140,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> {
        const data =  params.row.createdAt ? params.row.createdAt.toDate() : '2023-01-15'
        return clearData(data)
      }
    },
    {
      field: 'oferta',
      headerName: 'Produto',
      width: 150,
      editable: true,
      valueGetter : (params: GridValueGetterParams)=> params.row.oferta.nome
    },
    {
      field: 'usuario',
      headerName: 'Contratante',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> usuario.nome
    },
    {
      field: 'valor',
      headerName: 'Valor investido',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> 'R$ '+params.row.valor + ',00'
    },
    {
      field: '1',
      headerName: 'Valor atualizado',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> {
        const newValue = Number(params.row.valor)
        const rendimento = Number(params.row.oferta.rendimento)
        //const date = new Date(params.row.oferta.createdAt?.toDate()||'2023-01-15')
        const date:any =  params.row.createdAt.toDate()
        return calcularMontante(newValue, rendimento, date)
      }
    },
    /* {
      field: 'transacao',
      headerName: 'Status',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> {
        const st = params.row.transacao.status
        if(st != "COMPLETED" && st != "EXPIRED"){
          checkStatus(params.row.transacao.id, params.row);
        }
        return statusFilter(st)
        
        
      }
    }, */
    
    

    
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const cat = aplicacoesList.filter((item:any)=>item._id==id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          
          
          <GridActionsCellItem
            icon={ <Tooltip TransitionComponent={Zoom}   title="Detalhes" arrow><ViewHeadline className='iconDetail' /></Tooltip>}
            label="Detalhes"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          /* <GridActionsCellItem
          
            icon={ <Tooltip TransitionComponent={Zoom}   title="Excluir" arrow><DeleteIcon className='iconDetail' /></Tooltip>}
            label="Delete"
            onClick={()=>{
              handleDeleteClick(id)
            }}
            color="inherit"
          />, */
          
        ];
      },
    },
    
    
  ];

  const hiddenFields = ['_id','id','ID'];

const getTogglableColumns = (columns: GridColDef[]) => {
  console.log('getTogglableColumns',columns)
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};

interface AutocompleteOption {
  categoria: string;
}

const clearData = (d:any)=>{
  const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
  return dateTransform
}

const changeFilter = ()=>{

  const objToSend = {
    //startDate,
    //endDate,
    empresa: usuario.empresa,
    //categoria:selectedCategory?._id || '',
    usuario : selectedUser?._id || '',
    //produto : selectedProduto?._id || '',
  }
  console.log('objToSend', objToSend)
  //getInventoriesByFilter(objToSend)

}

const handleSaveClick = (id: GridRowId) => () => {
  const el = rows.filter((item:any)=>item._id == id)
  console.log('handleSaveClick', id)
  console.log('handleSaveClick', el)
  setSave(true)
  setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
};
  
  

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
          <div className={classes.content}>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography variant="h4" className={'title'}>
            Suas Aplicações
          </Typography>
          <Button className={classes.button} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() =>navigate('/ofertas') }>
            Ofertas
          </Button>




          
          {rows.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={loading}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
          disableRowSelectionOnClick
          
          onClipboardCopy={(copiedString) => console.log(copiedString)}
          unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'unidadesDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            Faça sua primeira aplicação agora. 

          </div>)}


          {/* {aplicacoesList.length ? (
            <div className='listContainer'>
              <List>
                {aplicacoesList.map((categoria: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <Typography className='text'>{categoria.nome}</Typography>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'space-evenly', }}>
                      

                      <Tooltip TransitionComponent={Zoom}   title="Transferir" arrow>
                        <IconButton color="primary" aria-label="Ver ítens" onClick={() => handleProdutosbyCategoria(categoria)}>
                          <Typography  className='text'>{'ver itens'}</Typography>
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Editar" arrow>
                        <IconButton color="primary" aria-label="Editar" onClick={() => handleEditCategoria(categoria)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
                        <IconButton
                          color="secondary"
                          aria-label="Deletar"
                          className={classes.deleteButton}
                          onClick={() => handleDeleteClick(categoria._id)}
                        >                        
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={addCategoriaModalOpen} onClose={() => setAddCategoriaModalOpen(false)}>
        <DialogTitle>Adicionar</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Unidade"
            value={newCategoriaName}
            onChange={(e) => setNewCategoriaName(e.target.value)}
          />
        

            {errorMessageText ? (
                            <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>{errorMessageText}</Typography>
                            ):null}
    
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              //clearErrorMessage()
              setAddCategoriaModalOpen(false)
            }}>Cancelar</Button>
          <Button onClick={handleAddCategoria} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      


      <Dialog open={productsModalOpen} style={{minWidth:600, minHeight:500}} onClose={() =>{
            setProductsModalOpen(false)
            setNewUnidadeVisible(false)
          }}>
        <DialogTitle>Unidade {editCategoriaData.nome}</DialogTitle>
        <DialogContent className={classes.modalContent}>

        {/* <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Ítens</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput label="Ítens selecionados" />}
                renderValue={(selected:any) => selected.join(', ')}
                MenuProps={MenuProps}
                maxRows={5}
                //open={true}
                autoWidth
                fullWidth={false}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : ""
                  }}
                >
                  
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 && selected.length < produtosList.length
                      }
                    />
                  
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Todos"
                  />
                </MenuItem>
                {produtosList.length ? (
                  produtosList.map((item:any) => (
                    <MenuItem key={item.nome} value={item.nome}>
                      
                      <Checkbox checked={selected.indexOf(item.nome) > -1} />
                      <ListItemText primary={item.nome} />
                    </MenuItem>
                  ))

                ): <div>
                Nenhum ítem encontrado
                </div>}
              </Select>
            </FormControl> */}
          
          {
            newUnidadeVisible ? (
            <div>
              
              <FormControl fullWidth >
              <InputLabel className={'text'} htmlFor="tipo-select">Escolha a nova Unidade</InputLabel>
              <Select
              
                className={'select'}
                value={selectedNewCategoria}
                onChange={(e:any) => {
                  //setSelectedCategoria( e.target.value )
                  //changeCategoria( e.target.value)
                  
                }}
                inputProps={{
                  name: 'categoria',
                  id: 'tipo-select',
                }}
              >
                {categoriasToShow.map((cat: { _id: any; nome: any; }) => (
                  <MenuItem className={'text'} key={cat._id} value={cat._id}>
                    {cat.nome}
                  </MenuItem>
                ))}
              </Select>
              {
                errorMessageLocal ? (<div style={{color:'red', backgroundColor:'black', margin:15}}>
                  {errorMessageLocal}
                </div>) : null
              }
              {
                selectedNewCategoria ? (
                  <div style={{display: 'flex',
                              flexDirection: 'column',
                              flexWrap: 'nowrap',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: 15,
                              }}>
                    Categoria destino: {getCategoriaNome(selectedNewCategoria)}
                    
                    {
                      sending ? (
                        <div style={{margin:30, fontSize:18}}>
                          enviando {index+1} de {itensSendList.length}
                        </div>
                      ) : null
                    }
                    {
                      showMessage ? (
                        <div style={{margin:30, fontSize:18}}>
                          envio concluído
                      </div>
                      ) : null
                    }
                    {
                      !showMessage&&!sending ? 
                      (<Button className='text' onClick={handleConcluir}>Finalizar</Button>) : null
                    }
                    
                  </div>
                ) : null
              }

            </FormControl>
            </div>
            ) : (
            <Box>
            <FormGroup >
            <FormControlLabel control={
              <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      value={'all'}
                      indeterminate={
                        selected.length > 0 && selected.length < produtosList.length
                      }
                      onChange={handleChange}
              />} label="Todos" />
            {produtosList.length ? (
              produtosList.map(
                (item:any)=>
                  <div className='text'>
                    <FormControlLabel control={<Checkbox value={item.nome} onChange={handleChange} checked={selected.indexOf(item.nome) > -1} />} label={item.nome} />
                  </div>
              )
              
            ) : (<div>
              Nenhum ítem encontrado
              </div>) }
            </FormGroup>
          </Box>)
          }
          

        </DialogContent>
        <DialogActions>
          
          {
            !newUnidadeVisible ? (
              <Button disabled={!selected.length} onClick={() => {
                handleSelectCategoria()
              }}>Transferir</Button>
            ) : null
          }
          
          <Button onClick={() => {
            setProductsModalOpen(false)
            setNewUnidadeVisible(false)
          }}>Fechar</Button>
          {/* <Button onClick={handleUpdateCategoria} color="primary">
            Atualizar
          </Button> */}
        </DialogActions>
      </Dialog>

      
{/* <Modal open={editCategoriaModalOpen} onClose={() => setEditCategoriaModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editCategoriaData.nome}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.email}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, email: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.empresa}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, empresa: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.cnpj}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateCategoria}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user.user ,
  
});

const mapDispatchToProps = {
    
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AplicacoesPage);
