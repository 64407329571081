import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/Avatar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getAuth, updateProfile, signInWithEmailAndPassword, createUserWithEmailAndPassword  } from 'firebase/auth';
import { getDatabase, ref, set } from "firebase/database";
import { getFirestore, doc, setDoc,getDoc } from "firebase/firestore";
import {auth, initApp, update} from './../firebase';
import { useNavigate } from 'react-router-dom'
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://landbankinvestimentos.com.br/">
        wwww.landbankinvestimentos.com.br
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email:any =  data.get('email')
    const password:any = data.get('password')
    const passwordConfirm:any = data.get('passwordConfirm')
    const telefone:any = data.get('telefone')
    const firstName:any = data.get('firstName')
    const lastName:any = data.get('lastName')
    console.log(data);

        const auth = getAuth();
        try {
            const newUser = await createUserWithEmailAndPassword(auth, email, password);
            console.log('Usuário criado com sucesso!', newUser);
            console.log('uid',newUser.user.uid)
            const userId = newUser.user.uid
            
            
            /* set(ref(base, 'usuarios/' + userId), {
              nome: firstName,
              sobrenome: lastName,
              email: email,
              telefone: telefone,
            }); */
            const dados = {
              nome: firstName,
              sobrenome: lastName,
              email: email,
              telefone: telefone,
            }
            update("usuarios", userId, dados)
            
            const message:any = 'Usuário criado com sucesso'
            setSuccessMessage(message)
            navigate('/login');
            /* base.ref('usuarios/' + userId).set({
                  nome: firstName,
                  sobrenome: lastName,
                  email: email,
                  telefone: telefone,
                  
                  // Outros campos relevantes
              }); */
        } catch (error:any) {
            console.error('Erro ao criar usuário:', error);
            console.log(typeof error.message)
            if(error.message ){ // == 'Firebase: Error (auth/email-already-in-use).'
              let message =error.message
              if(error.message == 'Firebase: Error (auth/email-already-in-use).'){
                message = 'E-mail já esta cadastrado no sistema'
              }
              if(error.message == 'Firebase: Password should be at least 6 characters (auth/weak-password).'){
                message = 'Senha precisa conter pelo menos 6 digitos'
              }
              setErrorMessage(message)
              //alert("Este e-mail já está em uso.")
            }
        }
        //navigate('/login');

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        {/* <CssBaseline /> */}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#9b2727' }}>
            {/* <LockOutlinedIcon /> */}
          </Avatar>
          <Typography component="h1" variant="h5">
            Dados de acesso
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="telefone"
                  label="telefone"
                  name="telefone"
                  autoComplete="telefone"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="passwordConfirm"
                  label="Confirmação de senha"
                  type="password"
                  id="passwordConfirm"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                style={{paddingLeft:20}}
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="Eu quero receber novidades, materiais promocionais e atualizações via email."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor:'#9b2727' }}
            >
              Criar conta
            </Button>
            {errorMessage ? (
              <div style={{width:'100%', color:'red', margin:10, textAlign:'center'}}>
                {errorMessage}
              </div>   
            ) : null}
            {successMessage ? (
              <div style={{width:'100%', color:'green', margin:10, textAlign:'center'}}>
                {successMessage}
              </div>   
            ) : null}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Já tem uma conta? Logar
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}