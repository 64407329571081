import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@material-ui/core';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/store';
import { getLogs, setProdutoId, setLogSelecionado } from '../redux/produtos/actions';
import { useNavigate } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { useLoadScript } from '@react-google-maps/api';
import PinDrop from '@material-ui/icons/PinDrop';
import { green } from '@material-ui/core/colors';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';





const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    textAlign: 'center',
    minWidth:'90vw',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100vh',
    //backgroundColor : 'forestgreen',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(4),
    color:'#e8ca74',
  },
  text: {
    fontSize : 14,
    color: '#008800'
  },
  blockText: {
    fontSize : 14,
    color: '#000',
    marginBottom: 20,
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  balloon:{
    width:150,
    height:100,
    borderRadius:15,
    borderWidth:2,
    borderColor:'black',
    backgroundColor:'green',
    color:'white',
    padding:'10px',
  }
}));

const googleKey ="AIzaSyD6_lxrnSfNGuhKxErqagxzGb5AGuCcZbg";

type PropsFromRedux = ConnectedProps<typeof connector>;

const LocationPage: React.FC<PropsFromRedux> = ({ isLogged,userPosition, setLogSelecionado, logs, log, user, produtoId}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [defaultProps, setDefaultProps] = React.useState<any>({});
  const [selectedPin, setSelectedPin] = React.useState<any>({});
  const [long, setLong] = React.useState<any>('');
  const [lat, setLat] = React.useState<any>('');
  const [zip, setZip] = React.useState<any>('');
  const [userLocationList, setUserLocationList] = React.useState<any>([]);
  //const [maps, setMaps] = React.useState<any>('');

  useEffect(()=>{console.log('logs ==>>> ',logs)},[logs])
  useEffect(()=>{
    console.log('userPosition ==>>> ',userPosition)
    /* 635c075596a268680647ce02
        : 
        position
        : 
        {timestamp: 1692197751832, mocked: false, provider: 'fused', coords: {…}}
        user
        : 
        {_id: '635c075596a268680647ce02', empresa: '635c074796a268680647cdff', nome: 'Tiago Hilkner Venegas', email: 'tiagovenegas@gmail.com', ativo: true} */
        for(var i in userPosition){
            const newValue = userPosition[i]
            newValue.id = i;
            console.log('newValue ==> ',newValue)
            if(!defaultProps.center) {
                const lat_ = newValue.position.coords.latitude;
                const lng_ = newValue.position.coords.longitude;
                
                setDefaultProps ( {
                    center: {
                    lat : lat_, lng:lng_
                    },
                    zoom: 11
                })
                console.log('definiu o center', defaultProps)
                //getPosition()
                
                getAddress(lat_,lng_)

            }
        
            const filterUser = userLocationList.filter((item:any)=> item.id == i)
            const userLocationListCopy = [... userLocationList]
            console.log('filterUser ==>>> ',filterUser)
            if(!filterUser.length){
                console.log('entrou pra gravar')
                setUserLocationList([... userLocationList ,newValue])
            } else {
                for(var z=0 ; z<userLocationList.length ;z++){
                    const item = userLocationList[z];
                    if(item.id == i){
                        console.log('atualizou', newValue)
                        userLocationListCopy[z] = newValue 
                    }
                    setUserLocationList(userLocationListCopy)
                }

            }
        }
        
  },[userPosition])
  useEffect(()=>{
    console.log('user ==>> ' , user);
    console.log('log ==>> ' , log);
    if(log&&log?.localizacao) {
        console.log('definiu')
        
        const lat_ = log.localizacao.coords.latitude
        const lng_ = log.localizacao.coords.longitude
        setDefaultProps ( {
            center: {
              lat : lat_, lng:lng_
            },
            zoom: 11
          })
          
        //getPosition()
         
        getAddress(lat_,lng_)
    }
    /* setTimeout(()=>{
      goNext()
    },5200) */
    },[log])

    const checkGoogleMaps = (maps:any)=>{

      var googleMaps = maps ||
      (window.google && // eslint-disable-line no-extra-parens
        window.google.maps) 

      if (!googleMaps) {
        console.error(// eslint-disable-line no-console
          'Google map api was not found in the page.')
        return
      }
      // now grab the services we need
      
      //const autocompleteService = new googleMaps.places.AutocompleteService()
      const geocoder = new googleMaps.Geocoder()
      console.log('geocoder check', geocoder)
    }
    const goNext = ()=>{
      let next = 0
      logs.map((item:any, index:any)=>{
        if(item._id == log._id){
          next = index + 1
        }
      })
      if(next >= logs.length) next = logs.length - 1
      console.log('next ==>> ',next)
      const newLog = logs[next]
      setLogSelecionado(newLog)


    }
    const goBack = ()=>{
      let next = 0
      logs.map((item:any, index:any)=>{
        if(item._id == log._id){
          next = index - 1
        }
      })
      if(next < 0 ) next = 0
      console.log('next ==>> ',next)
      const newLog = logs[next]
      setLogSelecionado(newLog)


    }

    const changeLog = (index:any)=>{
      if(index==1) goNext()
      else goBack()

    }

    const getDateLabel = (date:any)=>{
      const data = new Date(date);
      const dia = data.getDate();
      const mes = data.getMonth() + 1;
      const ano = data.getFullYear();
      const label = (`${dia}/${mes}/${ano}`);
      return label
    }
    const getFloor = async (maps:any)=>{
      // Create a geocoder instance
    //var geocoder = maps.Geocoder();
    console.log('getFloor ===>>> ',maps)
    const geocoder = await maps.Geocoder();
   // var geocoder = new window.google.maps.Geocoder();
    
    // Crie uma solicitação de geocodificação reversa
    const geocodeRequest = {
      location: {lat: lat, lng: long},
    };
    console.log('geocoder ===>>> ',geocoder)
    // Realize a geocodificação reversa
    geocoder.geocode(geocodeRequest, (results:any, status: any) => {
      if (status ==='OK') {
        if (results[0]) {
          let floor = null;
          for (let i = 0; i < results[0].address_components.length; i++) {
            const component = results[0].address_components[i];
            if (component.types.includes('floor')) {
              floor = component.long_name;
              break;
            }
          }

          if (floor) {
            console.log('Floor: ', floor);
          } else {
            console.log('Floor information not available.');
          }
        } else {
          console.log('No results found.');
        }
      } else {
        console.log('Geocoder failed due to:', status);
      }
    });
  }

  /*  const { isLoaded, loadError } = useLoadScript({
      googleMapsApiKey:googleKey,
    });
  
    if (loadError) {
      console.log(loadError)
      //return <div>Error loading Google Maps</div>;
    } 
    if (isLoaded) {
      
      //return <div>Error loading Google Maps</div>;
    } */

  
  
  
  const handleApiLoaded = (map:any, maps:any) => {
    //setMaps(maps)
    //getFloor(maps)
    checkGoogleMaps(maps)
    // use map and maps objects
  };


  // If browser supports navigator.geolocation, generate Lat/Long else let user know there is an error
    const getPosition = () => {
        if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, posError); // Passing in a success callback and an error callback fn
        } else {
        alert("Sorry, Geolocation is not supported by this browser."); // Alert is browser does not support geolocation
        }
    }
    // Geolocation error callback fn. Query permissions to check if the error occured due to user not allowing location to be shared
    const posError = () => {
        if (navigator.permissions) {
        navigator.permissions.query({ name: 'geolocation' }).then(res => {
        if (res.state === 'denied') {
        alert('Enable location permissions for this website in your browser settings.')
        }
        })
        } else {
        alert('Unable to access your location. You can continue by submitting location manually.') // Obtaining Lat/long from address necessary
        }
    }
        // Geolocation success callback fn
    const showPosition = (position:any) => {
        let lat = position.coords.latitude // You have obtained latitude coordinate!
        let long = position.coords.longitude // You have obtained longitude coordinate!
        setLat(lat) // Using dispatch to modify lat store state
        setLong(long) // Using dispatch to modify long store state
        //getAddress(lat, long) // Will convert lat/long to City, State, & Zip code
    }
        // Fetching for google API key from back-end (Optional, you can store it in .env file in front-end)
    /* const convertToAddress = (lat:any, long:any) => {
        fetch('http://localhost:3000/googlemaps')
        .then(res => res.json())
        .then(obj => getAddress(lat, long))
    } */
    // Converting lat/long from browser geolocation into city, state, and zip code using Google Geocoding API
    const getAddress = (lat:any, long:any) => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${googleKey}`)
        .then(res => res.json())
        .then(address => {
          console.log('zip ==> ',address)
          setZip(address.results[0].formatted_address)
        })
    }

  const renderMarker = (map:any, maps:any) => {
    let marker = new maps.Marker({
    position: defaultProps.center,
    map,
    title: "Item Location"
    });
    return marker;
};

  const Balloon = (obj:any) =>{
    return (
    <div className={classes.balloon}>
      <div>{obj.text}</div>
      <div>{zip}</div>
    </div>
  );
  } 
  
  const AnyReactComponent = (obj:any) => {
    //console.log(obj)
    //return <div>{obj.text}</div>
    return (<div onClick={()=>{
        console.log('item', obj)
        setSelectedPin(obj.item)
    }}><PinDrop /></div>)
};

const fabStyle = {
  position: 'absolute',
  top: 150,
  left: 150,
};

const fabStyleRight = {
  position: 'absolute',
  top: 150,
  right: 150,
};

const fabGreenStyle = {
  color: 'common.white',
  bgcolor: green[500],
  '&:hover': {
    bgcolor: green[600],
  },
};

const transitionDuration = {
  enter: theme.transitions.duration.enteringScreen,
  exit: theme.transitions.duration.leavingScreen,
};

const fabs = [
  {
    color: 'primary' as 'primary',
    sx: fabStyle as SxProps,
    icon: <LeftIcon />,
    label: 'Anterior',
  },
  {
    color: 'secondary' as 'secondary',
    sx: fabStyleRight as SxProps,
    icon: <RightIcon />,
    label: 'Próximo',
  },
  /* {
    color: 'inherit' as 'inherit',
    sx: { ...fabStyle, ...fabGreenStyle } as SxProps,
    icon: <UpIcon />,
    label: 'Expand',
  }, */
];
  
  
const value = 1

  
  return (
    <Container className={classes.container}>
      <div className={classes.root}>
      <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        <Typography variant="h4" component="h1" className={'title'}>
          Mapa de movimentação
        </Typography>

        {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === 1}
          timeout={transitionDuration}
          style={{
            
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Fab sx={fab.sx} aria-label={fab.label} color={fab.color} onClick={()=>{
            changeLog(index)
          }}>
            {fab.icon}
          </Fab>
        </Zoom>
      ))}
        {
            selectedPin&&selectedPin.position ? (
                <div style={{margin:'20px'}}>
                <Typography variant="h5" component="h2" className={'text'}>
                <div>data: {getDateLabel(selectedPin.position.timestamp)}</div>
                </Typography>
                <Typography variant="h5" component="h2" className={classes.text}>
                <div>Usuário: {selectedPin.user.nome}</div>
                </Typography>
                
                </div>
                
            ) : null
        }
        
        
        {/* <LoadScript googleMapsApiKey={googleKey}></LoadScript> */}
    {userLocationList.length&&defaultProps&&defaultProps.zoom ? (<div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        onClick={()=>setSelectedPin({})}
      >
        {
            userLocationList.length ? (
                userLocationList.map((item:any)=>{
                    return(
                        <AnyReactComponent
                            lat={item.position.coords.latitude}
                            lng={item.position.coords.longitude}
                            text={item.user.nome}
                            item={item}
                        />
                    )
                    
    
                }) 
            ): null

        }
        
        {selectedPin.user && (
          <Balloon
            lat={selectedPin.position.coords.latitude}
            lng={selectedPin.position.coords.longitude}
            text={selectedPin.user.nome}
          />
        )}
      </GoogleMapReact>
    </div>):null}
  

        <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        
        {/* <Button component={Link} to="/login" variant="contained" color="primary" className={classes.button}>
          Entrar
        </Button> */}
      </div>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLogged : state.auth.isLogged,
  userPosition : state.user.userPosition,
  log: state.produtos.logSelecionado,
  logs: state.produtos.logs,
  produtoId:state.produtos.produtoId,
  user: state.user.user, // Ajuste aqui para a propriedade correta do estado do usuário
});

const mapDispatchToProps = {
  setLogSelecionado,
  //updateRole: updateUserRole,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LocationPage);
