import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal, LinearProgress, FormGroup, FormControlLabel, Checkbox, ListItemText, OutlinedInput } from '@material-ui/core';
import { InputLabel, Select, MenuItem, InputAdornment } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { fetchEmpresas } from './../redux/empresas/actions'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoneyIcon from '@material-ui/icons/Money';
import { Snackbar } from '@mui/base/Snackbar';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { green } from '@material-ui/core/colors';
import { Email, Phone, Business, ViewHeadline } from '@material-ui/icons';
import { width } from '@mui/system';
import { Avatar, Collapse, dividerClasses } from '@mui/material';

import Notifications from './../components/notifications/Notifications';
import { Autocomplete, Card, CardActions, CardContent, CardHeader, CardMedia, Divider, Link, ListItemButton, ListSubheader, Stack, styled } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { serverTimestamp } from 'firebase/firestore'
import { auth, initApp, create, update, find, findAll, findQuery, makeSum } from './../firebase';


import QRCode from 'qrcode.react';
import { donationPost } from './../api/donationPost';
import { donationGet, Donation } from './../api/donationGet';
import { serverConfig } from './../api/apiConfig';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',

  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
    width: '450px'
  },
  content: {
    textAlign: 'center',
  },

  listContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'flex-start',

    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },

  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth: 300,
    minHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 500
  },
  indeterminateColor: {
    color: "green"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const CarteiraPage: React.FC<PropsFromRedux> = ({ usuario }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);

  const [selectedCategoria, setSelectedCategoria] = React.useState('');
  const [selectedNewCategoria, setSelectedNewCategoria] = React.useState('');
  const [addCarteiraModalOpen, setAddCarteiraModalOpen] = React.useState(false);
  const [newCarteiraValue, setNewCarteiraValue] = React.useState<any>(500);
  const [newCategoriaEmpresa, setNewCategoriaEmpresa] = React.useState('');
  const [editData, setEditData] = React.useState<any>({});
  const [ofertasList, setofertasList] = React.useState<any>({});
  const [produtosList, setProdutosList] = React.useState<any>({});
  const [editCarteiraModalOpen, setEditCarteiraModalOpen] = React.useState(false);
  const [productsModalOpen, setProductsModalOpen] = React.useState(false);
  const [newUnidadeVisible, setNewUnidadeVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [ofertas, setOfertas] = React.useState<any[]>([]);
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false);
  const [categoriasToShow, setCategoriasToShow] = React.useState([{ nome: 'Todas', _id: 0 }]);
  const [errorMessageLocal, setErrorMessageLocal] = React.useState('');
  const [errorMessageText, setErrorMessageText] = React.useState<any>('');
  const [sending, setSending] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [itensSendList, setItensSendList] = React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [donation, setDonation] = React.useState<any>(null);
  const [saldo, setSaldo] = React.useState<any>(0);
  const [carteira, setCarteira] = React.useState<any>(null);
  const [carteiraList, setCarteiraList] = React.useState<any>(null);
  const [comment, setComment] = React.useState<string>('');
  const [error, setError] = React.useState<string | null>(null);
  const apiRef = useGridApiRef();

  const [expanded, setExpanded] = React.useState<any>('');
  const handleExpandClick = (id: string) => {
    const newValue = expanded == id ? '' : id
    setExpanded(newValue);
  };

  const handleChange = (event: any) => {
    const valor: any = isAllSelected ? false : true;
    console.log(valor)
    setIsAllSelected(valor)
  };
  useEffect(() => {
    if (save) {
      //handleUpdateCategoria()
      setSave(false)
    }
    console.log('rows', rows)
  }, [rows]);
  useEffect(() => {

    setErrorMessageText('')

  }, [])

  const getEmpreendimentos = async () => {
    const valor: any = await findAll('empreendimentos')
    console.log('valor', valor)
    setOfertas(valor)
    setLoading(false)
  }
  const makeSaldo = async () => {
    const soma = await makeSum("carteira", "usuario", usuario.id, "valor")
    setSaldo(soma)
  }
  const getMovimentacoesCarteira = async () => {

    const valor: any = await findQuery('carteira', 'usuario', usuario.id)
    console.log('valor carteira====>', valor)
    setCarteiraList(valor)
    setRows(valor);
    setLoading(false)
    makeSaldo()
  }



  useEffect(() => {
    console.log('selected', selected)
  }, [selected]);

  useEffect(() => {
    console.log('usuario useEffect', usuario)
    if (usuario.id) {
      setLoading(true)
      getMovimentacoesCarteira()
    }
  }, [usuario]);

  useEffect(() => {

    if (ofertas.length) {
      const order = (a: any, b: any) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      const orderedCategorias = ofertas.slice().sort(order)
      console.log('orderedCategorias', orderedCategorias)
      setCategoriasToShow(orderedCategorias)
      setRows(orderedCategorias)
      setofertasList(orderedCategorias)
    }
  }, [ofertas]);


  const createDonation = async (value: any, comment: any) => {
    const payload = {
      comment,
      value, // always 1 cent per now
    }
    const result: any = await donationPost(payload);
    console.log({
      result,
    });
    if (result.error) {
      setError(result.error);
      return;
    }
    setDonation(result);
    return result;
  }

  const updateCarteira = async (carteiraId: any, dados: any) => {
    console.log("dados para update", dados)
    await update("carteira", carteiraId, dados)
    getMovimentacoesCarteira()

    return;
  }


  const checkStatus = async (id: any, newRow?: any) => {
    console.log("donationId", id)
    const lastStatus = donation?.status&&!newRow?.transacao?.status ? donation.status : newRow.transacao.status
    console.log('lastStatus ==> ', lastStatus)

    const result: any = await donationGet(id);
    setDonation(result)
    console.log('result.status ==> ', result.status)
    /* console.log(
      'donation result', result
    ); */

    if (result.status != lastStatus) {


      if (newRow) {

        const dados = {
          ...newRow,
          status: result.status,
          transacao: result
        }
        if (result.status == "COMPLETED") {
          dados.ativo = true;
        }

        /* const updatedRow = { ...newRow }
        updatedRow.transacao.status = result.status
        console.log('updatedRow', updatedRow) */
        
        console.log('newRow._id', newRow._id)
        //setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
        await updateCarteira(newRow._id, dados)
      }


    }
    if (result.error) {
      setError(result.error);
      return;
    }

    return result
    //setDonation(result);
  }



  console.log({
    donation,
  });


  const statusFilter = (st: any) => {
    if (st === 'ACTIVE' || st == 'OPEN') {
      return 'Aguardando pagamento'
    } else if (st == 'COMPLETED') {
      return 'Transação concluída'
    }
    return st
  }

  const renderContent = () => {
    if (!donation) {
      return (
        <Box flex={1} mt='20px' ml='20px'>
          {/* <TextField label='Comment' value={comment} onChange={e => setComment(e.target.value)}/>
          <Button onClick={createDonation}>
            Donate
          </Button> */}
          {!!error && (<div>{error.toString()}</div>)}
        </Box>
      );
    }

    return (
      <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'center', paddingBottom: 10, borderColor: 'black', borderWidth: 2 }}>


        <div>Código:</div>
        <div style={{ width: '100%', overflowX: 'hidden' }}> {donation.brCode}</div>

        {
          donation.status === 'COMPLETED' ? (
            <Typography color={'green'} sx={{ mt: 2, mb: 1 }}>
              Transação concluída com sucesso!
            </Typography>


          ) : (
            <>
              <div style={{ color: 'red' }}></div>
              <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>
                {statusFilter(donation.status)}
              </Typography>
              <QRCode

                size={180}
                renderAs={'svg'}
                value={donation.brCode}
                includeMargin={false}
              />

              <Button onClick={() => {
                checkStatus(donation.id)
              }}>
                Validar pagamento
              </Button>
            </>
          )
        }


      </Box>
    )
  }





  const handleDeleteClick = (id: any) => {
    console.log('categoria ==>> ', id)
    setSelectedCategoria(id);
    setDeleteConfirmationOpen(true);
  };



  const handleDeleteConfirmation = () => {

    setDeleteConfirmationOpen(false);
  };


  const handleAddCarteira = async () => {


    setLoading(true)

    const valor = (Number(newCarteiraValue) || 500)
    const valorPix = (Number(newCarteiraValue) || 500) * 100

    console.log('valor', valor)

    const donationItem = await createDonation(valorPix, usuario.id);
    console.log('donationItem', donationItem)
    const dados = {
      tipo: 'entrada',
      status: "ACTIVE",
      valorTransacao: valor,
      valor,
      transacao: donationItem,
      'usuario': usuario.id,
      createdAt: serverTimestamp(),
      updateAt: serverTimestamp()
    }
    const registro = await create('carteira', dados)
    setCarteira(registro)
    setLoading(false)
    console.log('registro carteira',registro)
    //getMovimentacoesCarteira()
    //setNewCarteiraValue('');
    //setAddCarteiraModalOpen(false)

  };



  const handleEditCategoria = (categoria: any) => {
    console.log('categoria selecionada ==>> ', categoria)
    setEditData(categoria);
    setEditCarteiraModalOpen(true);
  };
  const handleOferta = (oferta: any) => {
    console.log('oferta selecionada ==>> ', oferta)
    oferta.valor = 500
    setEditData(oferta);
    setSelectedNewCategoria('')
    setProdutosList([])
    setSelected([])
    setErrorMessageLocal('')
    setSelectedIds([])
    setProductsModalOpen(true)
    setShowMessage(false)

  };





  const handleConcluir = () => {

    setIndex(0)
    console.log('itensList', itensSendList)
    setSending(true)
    sendNext(0)
  }


  const sendNext = (next: any) => {
    console.log('next', next)
    const item = itensSendList[next]
    console.log(item)

  }

  const getCategoriaNome = (id: any) => {
    const item = ofertas.filter((el: any) => el._id == id)
    return item[0].nome

  }


  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row: any) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row: any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row: any) => row._id !== id));
    }
  };

  const processRowUpdate: any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow', newRow)
    if (newRow.isNew) {
      delete newRow.isNew
    }
    setEditData(newRow)
    setRows(rows.map((row: any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };


  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type: 'Date',
      width: 140,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const data = params.row.createdAt ? params.row.createdAt.toDate() : '2023-01-15'
        return clearData(data)
      }
    },
    {
      field: 'transacao',
      headerName: 'Status',
      width: 150,
      editable: true,
      valueGetter: (params: GridValueGetterParams) => {
        const st = params.row.transacao.status
        return statusFilter(st)


      }
    },
    {
      field: 'valorTransacao',
      headerName: 'Valor Pix',
      width: 120,
      editable: false,
      //valueGetter: (params: GridValueGetterParams) => params.row.rendimento + '% a.a.'
    },
    {
      field: 'valor',
      headerName: 'Valor Atual',
      width: 120,
      editable: false,
      //valueGetter: (params: GridValueGetterParams) => params.row.rendimento + '% a.a.'
    },
    {
      field: 'utilizado',
      headerName: 'Utilizado',
      width: 120,
      editable: false,
      //valueGetter: (params: GridValueGetterParams) => params.row.rendimento + '% a.a.'
    },




    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        //
        let row:any = {}
        const rowList = rows.filter((item: any) => item._id == id)
        if(rowList?.length){
          row = rowList[0]

        }
        const st = row?.transacao?.status
        console.log('status tabela', st)
        if (st != "COMPLETED" && st != "EXPIRED") {
          return [

            <GridActionsCellItem
              icon={<Tooltip TransitionComponent={Zoom} title="Atualizar" arrow>
                <ViewHeadline className='iconDetail' />
              </Tooltip>}
              label="Atualizar"
              className="textPrimary"
              onClick={() => {
                checkStatus(row.transacao.id, row);
              }}
              color="inherit"
            />,
          ]

          

        }

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [

          <GridActionsCellItem
            icon={<Tooltip TransitionComponent={Zoom} title="Detalhes" arrow>
              <ViewHeadline className='iconDetail' />
            </Tooltip>}
            label="Detalhes"
            className="textPrimary"
            //onClick={() => handleOferta(oferta)}
            color="inherit"
          />,

          /*<GridActionsCellItem
            icon={ <Tooltip TransitionComponent={Zoom}   title="Detalhes" arrow><MoneyIcon className='iconDetail' /></Tooltip>}
            label="Detalhes"
            className="textPrimary"
            onClick={()=>handleOferta(oferta)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={ <Tooltip TransitionComponent={Zoom}   title="Editar" arrow><EditIcon className='iconDetail' /></Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
           <GridActionsCellItem
          
            icon={ <Tooltip TransitionComponent={Zoom}   title="Excluir" arrow><DeleteIcon className='iconDetail' /></Tooltip>}
            label="Delete"
            onClick={()=>{
              handleDeleteClick(id)
            }}
            color="inherit"
          />, */

        ];
      },
    },


  ];

  const hiddenFields = ['_id', 'id', 'ID'];

  const getTogglableColumns = (columns: GridColDef[]) => {
    console.log('getTogglableColumns', columns)
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };

  interface AutocompleteOption {
    categoria: string;
  }

  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

  const changeFilter = () => {

    const objToSend = {
      //startDate,
      //endDate,
      empresa: usuario.empresa,
      //categoria:selectedCategory?._id || '',
      usuario: selectedUser?._id || '',
      //produto : selectedProduto?._id || '',
    }
    console.log('objToSend', objToSend)
    //getInventoriesByFilter(objToSend)

  }

  const openModalAdcionar = () => {
    setDonation(null)

    setAddCarteiraModalOpen(true)
  }

  const handleSaveClick = (id: GridRowId) => () => {
    const el = rows.filter((item: any) => item._id == id)
    console.log('handleSaveClick', id)
    console.log('handleSaveClick', el)
    setSave(true)
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };



  function acceptRequest(item: any): void {
    throw new Error('Function not implemented.');
  }

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.content}>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography variant="h4" className={'title'}>
            Carteira
          </Typography>
          <Typography variant="h5" className={'title'}>
            Saldo atual:  R$ {saldo},00
          </Typography>
          <Button className={classes.button} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => openModalAdcionar()}>
            Adicionar

          </Button>

          {rows.length ? (
            <Box sx={{ width: '100%' }}>

              <DataGrid
                apiRef={apiRef}
                loading={loading}
                getRowId={(row: { _id: any; }) => row._id}
                //checkboxSelection
                disableRowSelectionOnClick

                onClipboardCopy={(copiedString) => console.log(copiedString)}
                unstable_ignoreValueFormatterDuringExport
                autoHeight={true}
                rows={rows}
                columns={columns}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'createdAt', sort: 'asc' }],
                  },

                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                //checkboxSelection

                //editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                // slots={{
                //   toolbar: EditToolbar,
                // }}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    setRows, setRowModesModel, csvOptions: {
                      fileName: 'usuariosDataBase_',
                      delimiter: ';',
                      utf8WithBom: true,
                    },
                    columns: {
                      columnVisibilityModel: { ID: false, },
                    },
                    columnsPanel: {
                      getTogglableColumns,
                    },
                    printOptions: { hideToolbar: true, disableToolbarButton: false }
                  },
                }}



              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },


              />

            </Box>
          ) : (<div style={{ margin: 15 }}>
            Nehum registro encontrado
          </div>)}

          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addCarteiraModalOpen} onClose={() => setAddCarteiraModalOpen(false)}>
        <DialogTitle>Adicionar saldo a carteira</DialogTitle>
        <DialogContent className={classes.modalContent}>
          {
            !donation?.id ? (
              <>
                <Typography color={'black'} sx={{ mt: 2, mb: 1 }}>
                  Defina o valor. Gere o código Pix e faça o pagamento. O saldo será atualizado em sua carteira.</Typography>
                <TextField
                  type='number'
                  label="valor"
                  inputProps={{
                    min: 50,
                    max: 100000,
                    step: 500

                  }}
                  value={newCarteiraValue}
                  onChange={(e) => setNewCarteiraValue(e.target.value)}
                />
                <Button onClick={handleAddCarteira} color="primary">
                  Gerar código pix
                </Button>

              </>
            ) : (
              <>
                <Typography color={'black'} sx={{ mt: 2, mb: 1 }}>
                  Código pix para o valor de R$ {newCarteiraValue},00
                </Typography>
                {renderContent()}
              </>

            )
          }


          <>
            <span> </span>

          </>
          {errorMessageText ? (
            <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>{errorMessageText}</Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          {
            !donation?.id ? (
              <>
                <Button onClick={() => {
                  //clearErrorMessage()
                  setAddCarteiraModalOpen(false)

                }}>Cancelar</Button>

                {/* <Button onClick={handleAddCarteira} color="primary">
                  Gerar código pix
                </Button> */}
              </>


            ) : (
              <>
                <Button onClick={() => {
                  checkStatus(donation.id)
                }} color="primary">
                  Atualizar
                </Button>
                <Button onClick={() => {
                  setAddCarteiraModalOpen(false)
                  getMovimentacoesCarteira()
                }} color="primary">
                  Fechar
                </Button>



              </>

            )
          }


        </DialogActions>
      </Dialog>






      {/* <Modal open={editCarteiraModalOpen} onClose={() => setEditCarteiraModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editData.nome}
      onChange={(e) => setEditData({ ...editData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editData.email}
      onChange={(e) => setEditData({ ...editData, email: e.target.value })}
    />
    <input
      type="text"
      value={editData.empresa}
      onChange={(e) => setEditData({ ...editData, empresa: e.target.value })}
    />
    <input
      type="text"
      value={editData.cnpj}
      onChange={(e) => setEditData({ ...editData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateCategoria}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuario: state.user.user,

});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CarteiraPage);
