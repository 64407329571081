import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, List, ListItem, Button} from '@material-ui/core';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
import { fetchEmpresas } from '../redux/empresas/actions';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100vh',
  },
  
  content: {
    textAlign: 'center',
  },
  title: {
    color:'#e8ca74',
    marginBottom: theme.spacing(2),
  },

  
 
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const AdminPage: React.FC<PropsFromRedux> = ({ empresas, fetchEmpresas }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    fetchEmpresas();
  }, [fetchEmpresas]);

  useEffect(() => {
    console.log('empresas',empresas);
  }, [empresas]);

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
      <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        <Typography className={'title'}>
          Área do administrador
        </Typography>
        <Button component={Link} to="/categorias" variant="contained" color="primary" className={classes.button} onClick={()=>{}}>
          Unidades
        </Button>
        <Button component={Link} to="/empresas" variant="contained" color="primary" className={classes.button} onClick={()=>{}}>
          Empresas
        </Button>
        <Button component={Link} to="/usuarios" variant="contained" color="primary" className={classes.button} onClick={()=>{}}>
          Usuários
        </Button>
        <Button component={Link} to="/produtos" variant="contained" color="primary" className={classes.button} onClick={()=>{}}>
          Itens
        </Button>
        
      </div>
      
      
    
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  empresas: state.empresas.empresas,
});

const connector = connect(mapStateToProps, { fetchEmpresas });

export default connector(AdminPage);
