import React from 'react';
import './Footer.css';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Container from '@mui/material/Container';

import logo_color from './../../assets/images/logo.jpg'
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="#e1b948" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://landbankinvestimentos.com.br/">
        wwww.landbankinvestimentos.com.br
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const Footer = () => {
  return (
    // <footer className="footer">
    //   {/* Conteúdo do rodapé */}
      
    // </footer>
    <Box
    component="footer"
    sx={{
      py: 3,
      px: 2,
      mt: 'auto',
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[800],
    }}
  >
    <Container maxWidth="sm" style={{textAlign: 'center'}}>
      {/* <Typography variant="body1"></Typography> */}
      <img src={logo_color} height={40} alt="" />
      {/* <Copyright sx={{ mt: 5 }} /> */}
      
      <Copyright />
    </Container>
  </Box>
    

  );
};

export default Footer;
