import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Icon } from '@material-ui/core';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/store';
import { updateUserRole } from '../redux/auth/actions';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

import ViewHeadline from '@material-ui/icons/ViewHeadline';
import ImportExport from '@material-ui/icons/ImportExport';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import Apps from '@material-ui/icons/Apps';
import Category from '@material-ui/icons/Category';
import Dashboard from '@material-ui/icons/Dashboard';

import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100vh',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  saudacao: {
    fontsize:'1.2rem',
    marginBottom: theme.spacing(4),
    color:'white'
  },
  button: {
    width:'100%',
    height:'95%',
    margin: theme.spacing(.5),
    backgroundColor: '#e1b948',
    color: '#9c2726',
    '&:hover': {
      backgroundColor: '#9c2726',
      border:'2px solid green',
      color:'#e1b948',
      fontWeight:'bold',
      boxShadow: '0px 0px 10px beige',
      scaleX:1.1,
      scaleY:1.1,
    },
  },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const HomePage: React.FC<PropsFromRedux> = ({ isLogged, user, updateRole }) => {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);

  useEffect(()=>{
    console.log('user ==>> ' , user);
  },[user])
  useEffect(()=>{
    setTimeout(()=>{
      setChecked(true)
    }, 500)
  },[])

  const handleStudentClick = () => {
    const newUser = { ...user, role: 'student' };
    updateRole(newUser);
  };

  const handleTeacherClick = () => {
    const newUser = { ...user, role: 'teacher' };
    updateRole(newUser);
  };

 

    const icon = (props:any)=> {
      console.log(props)
      return (
        <Paper sx={{ m: 1 }} elevation={4}>
          <Box  sx={{ width: 180, height: 180 }}>
            
            {
              props==1?(
              <Button component={Link} to="/ofertas" variant="contained" color="primary" className={classes.button} onClick={handleStudentClick}>
                <ImportExport />
                Ofertas
              </Button>):null
            }
            {
              props==2?(<Button component={Link} to="/aplicacoes" variant="contained" color="primary" className={classes.button} onClick={handleTeacherClick}>
              <Category />
              Minhas aplicações
            </Button>):null
            }
            {
              props==3?(<Button component={Link} to="/usuario" variant="contained" color="primary" className={classes.button}>
              <Apps />
              Perfil
            </Button>):null
            }
            {
              props==4?(<Button component={Link} to="/carteira" variant="contained" color="primary" className={classes.button}>
              <Dashboard />
              Carteira
            </Button>):null
            }
            
        
        
        
            
          </Box>
        </Paper>
        )
    
    }

   
  

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <Typography variant="h4"  className={'title'}>
          Plataforma LandBank 
          
        </Typography>
        {/* <Typography variant="h5" component="h3" className={classes.saudacao}>
          Bem-vindo, {user.nome}
        </Typography> */}
        <Box sx={{ height: 400 }}>
        <Box
        sx={{
          '& > :not(style)': {
            display: 'flex',
            justifyContent: 'space-around',
            height: 200,
            width: 410,
            
          },
        }}
      >
        <div>
          <Box sx={{ width: '50%' }}>
            <Grow in={checked}>{icon(1)}</Grow>
          </Box>
            {/* Conditionally applies the timeout prop to change the entry speed. */}
            <Box sx={{ width: '50%' }}>
            <Grow
              in={checked}
              style={{ transformOrigin: '0 0 0' }}
              {...(checked ? { timeout: 1000 } : {})}
            >
              {icon(2)}
            </Grow>
            </Box>
        </div>
        <div>
          <Box sx={{ width: '50%' }}>
              <Grow
              in={checked}
              style={{ transformOrigin: '0 0 0' }}
              {...(checked ? { timeout: 1500 } : {})}
            >
              {icon(3)}
            </Grow>
            </Box>
            <Box sx={{ width: '50%' }}>
              <Grow
              in={checked}
              style={{ transformOrigin: '0 0 0' }}
              {...(checked ? { timeout: 2000 } : {})}
            >
              {icon(4)}
            </Grow>
            </Box>
        </div>
          
            
            

          </Box>
        </Box>
        
        {/* <Button component={Link} to="/login" variant="contained" color="primary" className={classes.button}>
          Entrar
        </Button> */}
      </div>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLogged : state.auth.isLogged,
  user: state.user.user, // Ajuste aqui para a propriedade correta do estado do usuário
});

const mapDispatchToProps = {
  updateRole: updateUserRole,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(HomePage);
