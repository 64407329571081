import * as api from '../../api';
import RfidApi from '../../api/rfidApi';
import {serverConfig} from '../../api/apiConfig';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as base64 from 'base-64';

// Defina o tipo de ação
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_POSITION_START = 'UPDATE_USER_POSITION_START';
export const UPDATE_USER_POSITION_SUCCESS = 'UPDATE_USER_POSITION_SUCCESS';
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'


export const updateToken = async (renewToken: any)=> {
    const rfidApi = new RfidApi(renewToken);
        const response:any = await rfidApi.Fetch({
          method: 'post',
          url: `${serverConfig.pathUseCases.auth.authRefresh}`
        });
  
        if(response.data){
          localStorage.setItem('AUTH_USER_TOKEN_KEY', response.data.token)		  
            localStorage.setItem('AUTH_USER_RENEW_TOKEN_KEY', response.data.renewToken)		  
          return response.data.token
        }
  
  }
  
  export function* verifyToken (token: any){
    // Decodifica o token em arquivo json
    const decodeToken=()=> {
      const myToken = token
      if(! myToken ) {
        return null;
      }
      console.log('test token ===>>>', (base64.decode(myToken.split('.')[1])).toString())
      return (base64.decode(myToken.split('.')[1])).toString();
    }
  
    const isExpired =(): boolean => {
      let tokenProps:any = decodeToken();
      if(typeof tokenProps == 'string')
        tokenProps = JSON.parse(tokenProps)
      console.log('myToken', tokenProps  )
      if(! tokenProps ) {
        return true;
      }
      const dtExpire = 1000 * tokenProps['exp'];
      console.log('dtExpire', tokenProps['exp'],dtExpire, + new Date());
      return ( (+ new Date() )> dtExpire ); 
    }
  
    const condicao = isExpired()
  
    return condicao
  
  }


  export const updateUserPosition = (payload:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log('update user pos', payload)
    dispatch({ type: UPDATE_USER_POSITION_SUCCESS, payload : payload })
  }
  export const setUser = (payload:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    console.log('update user ', payload)
    dispatch({ type: GET_USER_SUCCESS, payload : payload })
  }
  export const getUser = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    try {
        dispatch({ type: GET_USER_START, payload : null })
        let token =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
        const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
        
        console.log('token getUserAsync',token)
        let vt =  verifyToken(token);
        if(vt){
          token = await updateToken(renewToken)
          console.log('refreshToken ', token)
        }
    
        const rfidApi = new RfidApi(token);
        const response:any = await rfidApi.Fetch({
          method: 'get',
          url: `${serverConfig.pathUseCases.userApp.getUser}`,
        });
    
        console.log('response getUserAsync',response)
        
        const user = response.data.data
        const uniqueUserID = response.data.data._id
    
        console.log('response getUserAsync user: ', user);
        // user.token = token
       
         const params = {
           user, uniqueUserID
         }
         
         dispatch({ type: GET_USER_SUCCESS, payload : user })
         // yield put();
          
      } catch (error) {
        console.log("error", error)
        //put(getUserFailure('erro'));
      }
}