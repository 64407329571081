import React, { useState, useEffect } from 'react';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { loginStart, loginSuccess } from '../redux/auth/actions';
import { setUser } from '../redux/user/actions';
import { getUser } from '../redux/user/actions';
import { useNavigate } from 'react-router-dom'
import iconeLand from './../assets/images/logo.jpg'
import { motion } from "framer-motion";
import { RootState } from '../redux/store';
import {auth, initApp, update, find} from './../firebase';
import { getAuth, signInWithEmailAndPassword,   } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore, doc, setDoc,getDocs, getDoc , collection, query, where } from "firebase/firestore";



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //minHeight: '100vh',
    backgroundColor: 'transparent',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    marginTop:'50px'
  },
  textField: {
    margin: theme.spacing(1),
    width: '300px',
    color: 'green'
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  error: {
    color: 'red',
    marginTop: theme.spacing(1),
  },
  marca:{
    position: 'absolute',
    top: '110px',
    width:'220px',
  },
  box: {
    position: 'absolute',
    top: '110px',
    width: '250px',
    height: '180px',
    backgroundColor: 'white',
    zIndex:1,
    
  },
  container_anim: {
    position: 'absolute',
    top: '110px',
    width: '270px',
    height: '220px',
    backgroundColor: 'forestgreen',
  
    
  }
}));
type PropsFromRedux = ConnectedProps<typeof connector>;
  const LoginPage: React.FC<PropsFromRedux> = ({ isLogged, errorMessage }) => {
  const classes = useStyles();
  const [data, setData] = useState(null);

  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [localUser, setLocalUser] = useState<any>({});
  const [animation, setAnimation] = useState(0);
  const [motionStart, setMotionStart] = useState(0);

  useEffect(()=>{
    setTimeout(()=>setMotionStart(1),1400)
  },[])
  useEffect(()=>{
    if(errorMessage?.message){
      setError(errorMessage.message);
    }
  },[errorMessage])
  useEffect(()=>{
    if(isLogged){
      //dispatch(getUser())
      navigate('/');
    }
  },[isLogged])

  

    const handleLoginFirebase = async () => {
      
      try {
          if (email === '' || password === '') {
            setError('Por favor, preencha todos os campos');
            return;
          }
          await signInWithEmailAndPassword(auth, email, password);
          console.log('auth.currentUser',auth.currentUser)
          const retorno:any = auth.currentUser
          

          const base:any = getFirestore(initApp);
          
          // Create a query against the collection.
        
          const finduser = await find( 'usuarios', retorno.uid)

          if (finduser.exists()) {
            const user = finduser.data()
            user.photoURL = retorno.photoURL
            user.id = retorno.uid
            console.log('finduser',user)
            setLocalUser(user)
            dispatch(setUser(user))
            dispatch(loginStart(user)) .then((val:any) => {
              console.log('val',val)
              // Lógica adicional após o login bem-sucedido, se necessário
              //dispatch(loginSuccess()); // Altera o estado isLogged para true
              
            })
            .catch((error) => {
              console.log('erros no login',error)
              // Lógica para lidar com erros no login, se necessário
            });
          }
          else {
            console.log("No such document")
          }
        } catch (error:any) {
          console.error('Erro ao fazer login:', error.message);
      }
          /* 
          const usersRef = collection(base, "usuarios");
          const q = query(usersRef, where("email", "==", retorno.email));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
          });
          console.log('querySnapshot',querySnapshot) */
          
          //setUser(auth.currentUser);
        
    };

    const handleLogout = async () => {
        try {
            await getAuth().signOut();
            setUser(null);
        } catch (error:any) {
            console.error('Erro ao fazer logout:', error.message);
        }
    };


  const handleLogin = () => {
    
    if (email === '' || password === '') {
      setError('Por favor, preencha todos os campos');
      return;
    }

    // Chamada à ação de login do Redux
    dispatch(loginStart({ email, password })) .then((val:any) => {
      console.log('val',val)
      // Lógica adicional após o login bem-sucedido, se necessário
      //dispatch(loginSuccess()); // Altera o estado isLogged para true
      
    })
    .catch((error) => {
      console.log(error)
      // Lógica para lidar com erros no login, se necessário
    });;
    
    // Lógica adicional de autenticação, como chamar a API de login
    // e lidar com a resposta para despachar a ação `loggedSuccess`
  };

  return (
    <div className={classes.root}>
      
        {
          motionStart ? (
            <motion.img
              src={iconeLand}
              alt="landbank"
              className={classes.marca}
              initial={{ opacity: 0, y: 0 }} // Configuração inicial da animação
              animate={{ opacity: 1, y: 0 }} // Configuração de animação quando a imagem é renderizada
              transition={{ duration: 1}} // Duração da animação em segundos
              
            />

          ) : null
        }
        {
          motionStart ? (
            <motion.div
              //src={iconeLand}
              
              className={classes.box}
              initial={{  x: -20, scaleX:1 }} // Configuração inicial da animação
              animate={{  opacity:.5, x:180, scaleX:0 }} // Configuração de animação quando a imagem é renderizada
              transition={{ duration: 1.2 }} // Duração da animação em segundos
              onAnimationComplete={definition => {
                setAnimation(1)
              }}
            />
          ) : null
        }

      
      
      



    
    {
      animation ? (
        <form className={classes.form}>
          
        <TextField
          className={classes.textField}
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          className={classes.textField}
          label="Senha"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {error && <Typography className={classes.error}>{error}</Typography>}
        <Button className={classes.button} variant="contained" onClick={handleLoginFirebase}>
          Entrar
        </Button>
        <Button onClick={() => navigate('/signup')}>Ou crie uma conta</Button>
        
      </form>
        
        
        ) : null
        }
        
  

      
    </div>
  );
};


const mapStateToProps = (state: RootState) => ({
  isLogged : state.auth.isLogged,
  errorMessage : state.auth.errorMessage,
  //user: state.user.user, // Ajuste aqui para a propriedade correta do estado do usuário
});

const mapDispatchToProps = {
  
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(LoginPage);