import * as api from '../../api';
import RfidApi from '../../api/rfidApi';
import {serverConfig} from '../../api/apiConfig';
import * as base64 from 'base-64';
import axios from 'axios';
// Defina o tipo de ação
export const GET_USUARIOS = 'GET_USUARIOS';
export const GET_USUARIOS_SUCCESS = 'GET_USUARIOS_SUCCESS';
export const SET_IMAGE_PROFILE_SUCCESS = 'SET_IMAGE_PROFILE_SUCCESS';
export const GET_IMAGES = 'GET_IMAGES';
export const GET_IMAGES_SUCCESS = 'GET_IMAGES_SUCCESS';
export const GET_IMAGES_FAILURE = 'GET_IMAGES_FAILURE';
export const UPDATE_USUARIO = 'UPDATE_USUARIO';
export const DELETE_USUARIO = 'DELETE_USUARIO';
export const DELETE_USUARIO_SUCCESS = 'DELETE_USUARIO_SUCCESS';
export const CREATE_USUARIO = 'CREATE_USUARIO';
export const CREATE_USUARIO_SUCCESS = 'CREATE_USUARIO_SUCCESS';
export const GET_USUARIO_BY_ID_SUCCESS = 'GET_USUARIO_BY_ID_SUCCESS';
export const UPDATE_USUARIO_SUCCESS = 'UPDATE_USUARIO_SUCCESS';
export const UPDATE_USUARIO_FAILURE = 'UPDATE_USUARIO_FAILURE';
export const GET_USUARIOS_BY_FILTER = 'GET_USUARIOS_BY_FILTER';
export const GET_USUARIOS_BY_FILTER_SUCCESS = 'GET_USUARIOS_BY_FILTER_SUCCESS';
export const GET_USUARIOS_BY_FILTER_FAILURE = 'GET_USUARIOS_BY_FILTER_FAILURE';


export const updateToken = async (renewToken: any)=> {
  const rfidApi = new RfidApi(renewToken);
      const response:any = await rfidApi.Fetch({
        method: 'post',
        url: `${serverConfig.pathUseCases.auth.authRefresh}`
      });

      if(response.data){
        localStorage.setItem('AUTH_USER_TOKEN_KEY', response.data.token)		  
    	  localStorage.setItem('AUTH_USER_RENEW_TOKEN_KEY', response.data.renewToken)		  
        return response.data.token
      }

}

export function* verifyToken (token: any){
  // Decodifica o token em arquivo json
  const decodeToken=()=> {
    const myToken = token
    if(! myToken ) {
      return null;
    }
    console.log('test token ===>>>', (base64.decode(myToken.split('.')[1])).toString())
    return (base64.decode(myToken.split('.')[1])).toString();
  }

  const isExpired =(): boolean => {
    let tokenProps:any = decodeToken();
    if(typeof tokenProps == 'string')
      tokenProps = JSON.parse(tokenProps)
    console.log('myToken', tokenProps  )
    if(! tokenProps ) {
      return true;
    }
    const dtExpire = 1000 * tokenProps['exp'];
    console.log('dtExpire', tokenProps['exp'],dtExpire, + new Date());
    return ( (+ new Date() )> dtExpire ); 
  }

  const condicao = isExpired()

  return condicao

}

export const setImageProfile = (image: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const produto = await api.getProdutoById(id);
  dispatch({ type: SET_IMAGE_PROFILE_SUCCESS, payload : image });
}

export const getUsuariosByFilter = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try {
    dispatch({ type: GET_USUARIOS_BY_FILTER, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    let vt = verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const params:any = payload;

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'post',
      body: params,
      url: `${serverConfig.pathUseCases.usuarios.getUsuariosByFilter}`,
    });

    console.log('GET_USUARIOS_BY_FILTER_SUCCESS  =====>>>>>>    ', response.data.data)
    dispatch({ type: GET_USUARIOS_BY_FILTER_SUCCESS, payload: response.data.data.data });
    //await put(getImagesByProductSuccess(response.data.data));

  } catch (error) {
    dispatch({ type: GET_USUARIOS_BY_FILTER_FAILURE, payload: error });
   // await put(getAllProductsFailure('erro'));
  }
}

export const getImagesByUser = (payload: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  try {
    dispatch({ type: GET_IMAGES, payload: null });
    let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
    const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
    
    console.log('token getUserAsync',token)
    let vt = verifyToken(token);
    if(vt){
      token = await updateToken(renewToken)
      console.log('refreshToken ', token)
    }

    const params:any = {referencia : 'usuario' , idReferencia: payload}

    const rfidApi = new RfidApi(token);
    const response:any = await rfidApi.Fetch({
      method: 'post',
      body: params,
      url: `${serverConfig.pathUseCases.usuarios.getImages}`,
    });

    console.log('getImagesByUser  =====>>>>>>    ', response.data)
    dispatch({ type: GET_IMAGES_SUCCESS, payload: response.data.data });
    //await put(getImagesByProductSuccess(response.data.data));

  } catch (error) {
    dispatch({ type: GET_IMAGES_FAILURE, payload: error });
  }
}



export const getUsuarios = () => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getUsuarios token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'get',
    body: {},
    url: serverConfig.pathUseCases.usuarios.getUsuarios,
  });
  console.log('response ==> ',response.data)
  dispatch({ type: GET_USUARIOS_SUCCESS, payload: response.data.data });
}

export const deleteUsuario = (usuarioId:any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  
  dispatch({ type: DELETE_USUARIO, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');

  console.log('deleteUsuario token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  
    const rfidApi = new RfidApi(token);


      const response: any = await rfidApi.Fetch({
        method: 'delete',
        body: {},
        url: serverConfig.pathUseCases.usuarios.deleteUsuario(usuarioId),
      });
  //const usuarios = await api.getusuarios();
  console.log('response ==> ',response.data)
  dispatch({ type: DELETE_USUARIO_SUCCESS, payload: response.data.data });
}

export const getUsuarioById = (id: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  //const usuario = await api.getUsuarioById(id);
  //dispatch({ type: GET_USUARIO_BY_ID_SUCCESS, payload: usuario });
}

export const createUsuario = (usuario: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: CREATE_USUARIO, payload: null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getUsuarios token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('usuario',usuario)
  const rfidApi = new RfidApi(token);
  const response: any = await rfidApi.Fetch({
    method: 'post',
    body: usuario,
    url: serverConfig.pathUseCases.usuarios.createUsuario,
  });
  console.log('response create usuario ==> ',response)
  dispatch({ type: CREATE_USUARIO_SUCCESS, payload: response.data.data });
}
export const uploadImage = async(data: any, file:any, token:any)=> {
  var axiosResponse
  const fileData = data;
  console.log('fileData init ===>>>>', fileData)
  console.log('fileData init ===>>>>', token)

 
    const config = {
        headers: {
          //Accept: '*/*',
          'Authorization':'Bearer ' + token,
          'Content-Type': 'multipart/form-data; charset=utf-8; boundary="another cool boundary";',
                
                //charset=utf-8; boundary="conteudo";
                //'x-amz-acl' : 'public-read'
        }
    };

      const form = new FormData();
      //form.append('data', JSON.stringify(data));
       Object.keys(fileData).forEach((key) => {
        console.log(key)
          form.append(key, fileData[key]);
      }); 
    
    
      if(file && !file.type && file.uri){
        const list = file.uri.split('.')
        const len = list.length
        file['type'] = 'image/'+list[len-1]
      }
      if(!file.name)
        file['name'] = file.fileName;

      
      console.log("file.type",file)
      if(file && file.type){
        form.append("arquivo", file);
        //form.append('Content-Type',file.type)
        console.log('form 1 ====>> ',form)
        
          let endPoint = `${serverConfig.dev.url}${serverConfig.pathUseCases.produtos.uploadImage}`
          console.log('endPoint',endPoint)
          console.log('config',config)
          /* responseUpload = yield axios.post(endPoint, form, config).then(response => {
            console.log('response ===> ', response)
          })
          .catch(error => {
            // Lógica para lidar com erros da requisição
            console.log('erro upload ===>>> ', error)
          }); */


          try {
            axiosResponse = await axios.request({
              url: endPoint,
              method: 'post',
              data: form,
              headers: config.headers
              // withCredentials: true,
            });
            
            
          } catch (error) {
            axiosResponse = error;
            console.log('error api : ', axiosResponse);
          }
          console.log('api response upload : ', axiosResponse);
          

      }
      return axiosResponse;

  
}
export const updateUsuario = (usuario: any) => async (dispatch: (arg0: { type: string; payload: any; }) => void) => {
  dispatch({ type: UPDATE_USUARIO, payload : null });
  let token:any =  localStorage.getItem('AUTH_USER_TOKEN_KEY');
  console.log('getUsuarios token',token)
  const renewToken =  localStorage.getItem('AUTH_USER_RENEW_TOKEN_KEY');
  let vt = verifyToken(token);
  if(vt){
    token = await updateToken(renewToken)
    console.log('refreshToken ', token)
  }
  console.log('usuario',usuario)



  if(usuario.file){
    console.log('has file *****************')
    const dataToSend = 
      {
        "referencia": "usuario",
        "idReferencia": usuario._id,
        "titulo": "imagem",
        "descricao": "profile",
        "contador": usuario.contador
      }

      delete usuario.contador
      //console.log('has file *****************', dataToSend )
      //console.log('has file *****************', usuario.file )
      try{
          const sendFile = await uploadImage(dataToSend, usuario.file, token);
      } catch(err){
        console.log('erro sendfile ===>>> ',err)

      }
      
    delete usuario.file
  }

  try{
    const rfidApi = new RfidApi(token);
    const response: any = await rfidApi.Fetch({
      method: 'patch',
      body: usuario,
      url: `${serverConfig.pathUseCases.usuarios.updateUsuario(usuario._id)}`,
      
    });
    console.log('response edit usuario ==> ',response)
    getUsuarios()
    dispatch({ type: UPDATE_USUARIO_SUCCESS, payload : null });
    
  }catch(error){
    dispatch({ type: UPDATE_USUARIO_FAILURE, payload : null });
  }
  
}



// E assim por diante para as outras ações...
