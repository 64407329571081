import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal, LinearProgress, FormGroup, FormControlLabel, Checkbox, ListItemText, OutlinedInput } from '@material-ui/core';
import {  InputLabel, Select, MenuItem, InputAdornment } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
import { getCategorias, deleteCategoria, createCategoria, updateCategoria, clearErrorMessage } from '../redux/categorias/actions';
import { getProdutosByCategory, updateProduto, getProdutosContador} from '../redux/produtos/actions';
import { fetchEmpresas } from './../redux/empresas/actions'
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { green } from '@material-ui/core/colors';
import { Email, Phone, Business, ViewHeadline } from '@material-ui/icons';
import { width } from '@mui/system';
import { dividerClasses } from '@mui/material';
import { Autocomplete, Divider, ListItemButton, ListSubheader, Stack } from '@mui/material';
import moment from 'moment'
import { VictoryBar, VictoryPie } from 'victory';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
    
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
    width: '450px'
  },
  content: {
    textAlign: 'center',
  },
  
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    minWidth:300,
    minHeight:200,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 500
  },
  indeterminateColor: {
    color: "green"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const CategoriasPage: React.FC<PropsFromRedux> = ({ isLoadingCategoria,clearErrorMessage, errorMessage,getProdutosContador,produtosCountByCategory, isLoadingProdutosUpdate,usuario, produtos,updateProduto, empresas, fetchEmpresas,categorias, getCategorias, getProdutosByCategory, deleteCategoria, createCategoria, updateCategoria}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedCategoria, setSelectedCategoria] = React.useState('');
  const [selectedNewCategoria, setSelectedNewCategoria] = React.useState('');
  const [addCategoriaModalOpen, setAddCategoriaModalOpen] = React.useState(false);
  const [newCategoriaName, setNewCategoriaName] = React.useState('');
  const [newCategoriaEmpresa, setNewCategoriaEmpresa] = React.useState('');
  const [editCategoriaData, setEditCategoriaData] = React.useState<any>({});
  const [categoriasList, setCategoriasList] = React.useState<any>({});
  const [produtosList, setProdutosList] = React.useState<any>({});
  const [editCategoriaModalOpen, setEditCategoriaModalOpen] = React.useState(false);
  const [productsModalOpen, setProductsModalOpen] = React.useState(false);
  const [newUnidadeVisible, setNewUnidadeVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const isAllSelected =
  produtosList.length > 0 && selected.length === produtosList.length;
  const [categoriasToShow, setCategoriasToShow] = React.useState([{nome:'Todas',_id:0}]);
  const [errorMessageLocal, setErrorMessageLocal] = React.useState('');
  const [errorMessageText, setErrorMessageText] = React.useState<any>('');

  const [sending, setSending] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [itensSendList, setItensSendList] =React.useState<any[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const apiRef = useGridApiRef();

const handleChange = (event:any) => {
  const value = event.target.value;
  const checked  = event.target.checked
  console.log('value  ===== ', value)
  console.log('checked  ===== ', checked)
  if (value === "all") {
    const list = []
    const idsList = []
    for(let i=0 ; i < produtosList.length ; i++){
      list.push(produtosList[i].nome)
      idsList.push(produtosList[i]._id)

    }
    setSelected(selected.length === produtosList.length ? [] : list);
    setSelectedIds(selected.length === produtosList.length ? [] : idsList);
    //setSelectedIds( idsList);
    return;
  }
  if (checked)
    setSelected([value, ...selected]);
  else {
    let list = [...selected]
    list = list.filter((item)=> item != value)
    setSelected([...list]);
  }
};
useEffect(() => {
  if(save){
    handleUpdateCategoria()
    setSave(false)
  }
  console.log('rows' , rows)
}, [rows]); 
useEffect(() => {
  clearErrorMessage()
  setErrorMessageText('')

},[])

useEffect(()=>{
  if(errorMessage?.message) {      
    setErrorMessageText(errorMessage?.message)
    
    console.log('errorMessage',errorMessage.message)
  }else{
    setErrorMessageText('')
  }
},[errorMessage])
useEffect(() => {
  if(!isLoadingCategoria&&categoriasList.length){
    apiRef.current.setColumnVisibility('_id', false);
  }
  console.log('categoriasList', categoriasList)
}, [categoriasList]);
  useEffect(() => {
    setAddCategoriaModalOpen(false)
    getCategorias();
  }, [getCategorias]);
  useEffect(() => {
    getProdutosContador()
  }, [getProdutosContador]);
  useEffect(() => {
    if(!isLoadingProdutosUpdate && sending ){
      const newIndex = index +1
      console.log(newIndex)
      if(newIndex >= itensSendList.length){
        setShowMessage(true)
        setSending(false)
        getCategorias();
        getProdutosContador()
        return;
      }
      setIndex(newIndex)
      sendNext(newIndex)
    }
  }, [isLoadingProdutosUpdate]);
  useEffect(() => {
    if(productsModalOpen){
      setProdutosList(produtos)
    }
    console.log('produtos',produtos)
  }, [produtos]);
  useEffect(() => {
    
    fetchEmpresas();
  }, [fetchEmpresas]);
  useEffect(() => {
    console.log('selected', selected)
  }, [selected]);
  useEffect(() => {

    if(categorias.length){
      const order = (a:any,b:any)=>{
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }
      const orderedCategorias = categorias.slice().sort(order)
      console.log('orderedCategorias', orderedCategorias)
      setCategoriasToShow(orderedCategorias)
      setRows(orderedCategorias)
      setCategoriasList(orderedCategorias)
    }
  }, [categorias]);

  useEffect(() => {
    if(isLoadingCategoria){
      setLoading(true)
    }
    if(loading && !isLoadingCategoria){
      console.log('chamou o recarregar')
      getCategorias();
      setLoading(false)
      if(!errorMessage){
        setAddCategoriaModalOpen(false);
      }
      
    }
    console.log('isLoadingCategoria ==> ',isLoadingCategoria)
    
  }, [isLoadingCategoria]);

  const getCount=(categoriaId:any)=>{
    let achou = false;
    let count = 0
    produtosCountByCategory.map((item:any, index: any)=>{
      if(item._id == categoriaId){
        count = item.count

      }
      

    })
    return count
  }

  const handleDeleteClick = (id: any) => {
    console.log('categoria ==>> ',id)
    setSelectedCategoria(id);
    setDeleteConfirmationOpen(true);
  };

  const handleSelectCategoria = () => {
    
    setNewUnidadeVisible(true)
  };

  const handleDeleteConfirmation = () => {
    deleteCategoria(selectedCategoria);
    setDeleteConfirmationOpen(false);
  };

  const handleAddCategoria = () => {
    const novaCategoria = {
      nome: newCategoriaName,
      empresa: newCategoriaEmpresa,
      usuario : usuario._id
      
    };
    clearErrorMessage()
    createCategoria(novaCategoria);
    
    setNewCategoriaName('');
    setNewCategoriaEmpresa('');

  };
  


  const handleEditCategoria = (categoria:any) => {
    console.log('categoria selecionada ==>> ', categoria)
    setEditCategoriaData(categoria);
    setEditCategoriaModalOpen(true);
  };
  const handleProdutosbyCategoria = (categoria:any) => {
    console.log('categoria selecionada ==>> ', categoria)
    setEditCategoriaData(categoria);
    setSelectedNewCategoria('')
    setProdutosList([])
    setSelected([])
    setErrorMessageLocal('')
    setSelectedIds([])
    setProductsModalOpen(true)
    getProdutosByCategory(categoria._id);
    setShowMessage(false)
    
  };
  
  const handleUpdateCategoria = () => {
    console.log('editCategoriaData',editCategoriaData)
    updateCategoria(editCategoriaData)
    setEditCategoriaData({});
    //setEditCategoriaModalOpen(false);

    // Lógica para atualizar a categoria com os dados do modal
  };

  const changeCategoria = (cat:any)=>{
    
    console.log('categoria id: ',cat)
    if(editCategoriaData._id == cat){
      setErrorMessageLocal('Unidade destino não pode ser igual a atual')
      return;
    }
    setIndex(0)
    setSelectedNewCategoria(cat)
    setErrorMessageLocal('')
    const itensList:any = []
    selected.map((item:any, i:any)=>{
      console.log(item)
      const lista = produtos.filter((el:any)=>item == el.nome  )
      if(lista.length){
        const obj = {_id : lista[0]._id , categoria : cat}
        itensList.push(obj)
        setItensSendList (itensList)
      }
      
    })
    
    
    
  }
  
  const handleConcluir = ()=>{
    
    setIndex(0)
    console.log('itensList',itensSendList)
    setSending(true)
    sendNext(0)
  }


  const sendNext = (next:any) =>{
    console.log('next',next)
    const item = itensSendList[next]
    console.log(item)
    updateProduto(item)
  }

  const getCategoriaNome =(id:any)=>{
    const item = categorias.filter((el:any)=> el._id == id)
    return item[0].nome

  }


  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    if(newRow.isNew){
      delete newRow.isNew
    }
    setEditCategoriaData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
 

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 140,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'usuario',
      headerName: 'Operador',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> params.row.usuario?.nome
    },
    {
      field: 'ativo',
      headerName: 'Itens',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> getCount(params.row._id)
    },
    

    
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const cat = categoriasList.filter((item:any)=>item._id==id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          
          <GridActionsCellItem
            icon={ <Tooltip TransitionComponent={Zoom}   title="Transferir" arrow><ViewHeadline className='iconDetail' /></Tooltip>}
            label="Tranferir Itens"
            className="textPrimary"
            onClick={()=>handleProdutosbyCategoria(cat)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={ <Tooltip TransitionComponent={Zoom}   title="Editar" arrow><EditIcon className='iconDetail' /></Tooltip>}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
          
            icon={ <Tooltip TransitionComponent={Zoom}   title="Excluir" arrow><DeleteIcon className='iconDetail' /></Tooltip>}
            label="Delete"
            onClick={()=>{
              handleDeleteClick(id)
            }}
            color="inherit"
          />,
          
        ];
      },
    },
    
    
  ];

  const hiddenFields = ['_id','id','ID'];

const getTogglableColumns = (columns: GridColDef[]) => {
  console.log('getTogglableColumns',columns)
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};

interface AutocompleteOption {
  categoria: string;
}

const clearData = (d:any)=>{
  const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
  return dateTransform
}

const changeFilter = ()=>{

  const objToSend = {
    //startDate,
    //endDate,
    empresa: usuario.empresa,
    //categoria:selectedCategory?._id || '',
    usuario : selectedUser?._id || '',
    //produto : selectedProduto?._id || '',
  }
  console.log('objToSend', objToSend)
  //getInventoriesByFilter(objToSend)

}

const handleSaveClick = (id: GridRowId) => () => {
  const el = rows.filter((item:any)=>item._id == id)
  console.log('handleSaveClick', id)
  console.log('handleSaveClick', el)
  setSave(true)
  setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
};
  
  

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
          <div className={classes.content}>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography variant="h4" className={'title'}>
            Gerenciar Unidades
          </Typography>
          <Button className={classes.button} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddCategoriaModalOpen(true)}>
            Adicionar
          </Button>




          
          {rows.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingCategoria}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
          disableRowSelectionOnClick
          
          onClipboardCopy={(copiedString) => console.log(copiedString)}
          unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'desc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'unidadesDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            Nehum registro encontrado
          </div>)}


          {/* {categoriasList.length ? (
            <div className='listContainer'>
              <List>
                {categoriasList.map((categoria: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <Typography className='text'>{categoria.nome}</Typography>
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'space-evenly', }}>
                      

                      <Tooltip TransitionComponent={Zoom}   title="Transferir" arrow>
                        <IconButton color="primary" aria-label="Ver ítens" onClick={() => handleProdutosbyCategoria(categoria)}>
                          <Typography  className='text'>{'ver itens'}</Typography>
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Editar" arrow>
                        <IconButton color="primary" aria-label="Editar" onClick={() => handleEditCategoria(categoria)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
                        <IconButton
                          color="secondary"
                          aria-label="Deletar"
                          className={classes.deleteButton}
                          onClick={() => handleDeleteClick(categoria._id)}
                        >                        
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={addCategoriaModalOpen} onClose={() => setAddCategoriaModalOpen(false)}>
        <DialogTitle>Adicionar</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Unidade"
            value={newCategoriaName}
            onChange={(e) => setNewCategoriaName(e.target.value)}
          />
          <FormControl fullWidth>
              <InputLabel htmlFor="empresa-select">Selecione a Empresa</InputLabel>
              <Select
                value={newCategoriaEmpresa}
                onChange={(e:any) => setNewCategoriaEmpresa( e.target.value )}
                inputProps={{
                  name: 'empresa',
                  id: 'empresa-select',
                }}
              >
                {empresas.map((empresa: { _id: any; nome: any; }) => (
                  <MenuItem key={empresa._id} value={empresa._id}>
                    {empresa.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {errorMessageText ? (
                            <Typography color={'red'} sx={{ mt: 2, mb: 1 }}>{errorMessageText}</Typography>
                            ):null}
    
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              clearErrorMessage()
              setAddCategoriaModalOpen(false)
            }}>Cancelar</Button>
          <Button onClick={handleAddCategoria} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      { editCategoriaModalOpen && editCategoriaData.nome ?
      <Dialog open={editCategoriaModalOpen} onClose={() => setEditCategoriaModalOpen(false)}>
        <DialogTitle>Editar Unidade</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Unidade"
            value={editCategoriaData.nome}
            onChange={(e) => setEditCategoriaData({ ...editCategoriaData, nome: e.target.value })}
          />
          
          <TextField
            label="Empresa"
            value={editCategoriaData.empresa}
            onChange={(e) => setEditCategoriaData({...editCategoriaData, empresa : e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Business />
                </InputAdornment>
              ),
            }}
            error={editCategoriaData.empresa !== "" }

            helperText={
              !editCategoriaData.empresa &&
              'Empresa inválida'
            }
            />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditCategoriaModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateCategoria} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}


      <Dialog open={productsModalOpen} style={{minWidth:600, minHeight:500}} onClose={() =>{
            setProductsModalOpen(false)
            setNewUnidadeVisible(false)
          }}>
        <DialogTitle>Unidade {editCategoriaData.nome}</DialogTitle>
        <DialogContent className={classes.modalContent}>

        {/* <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Ítens</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selected}
                onChange={handleChange}
                input={<OutlinedInput label="Ítens selecionados" />}
                renderValue={(selected:any) => selected.join(', ')}
                MenuProps={MenuProps}
                maxRows={5}
                //open={true}
                autoWidth
                fullWidth={false}
              >
                <MenuItem
                  value="all"
                  classes={{
                    root: isAllSelected ? classes.selectedAll : ""
                  }}
                >
                  
                    <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      indeterminate={
                        selected.length > 0 && selected.length < produtosList.length
                      }
                    />
                  
                  <ListItemText
                    classes={{ primary: classes.selectAllText }}
                    primary="Todos"
                  />
                </MenuItem>
                {produtosList.length ? (
                  produtosList.map((item:any) => (
                    <MenuItem key={item.nome} value={item.nome}>
                      
                      <Checkbox checked={selected.indexOf(item.nome) > -1} />
                      <ListItemText primary={item.nome} />
                    </MenuItem>
                  ))

                ): <div>
                Nenhum ítem encontrado
                </div>}
              </Select>
            </FormControl> */}
          
          {
            newUnidadeVisible ? (
            <div>
              
              <FormControl fullWidth >
              <InputLabel className={'text'} htmlFor="tipo-select">Escolha a nova Unidade</InputLabel>
              <Select
              
                className={'select'}
                value={selectedNewCategoria}
                onChange={(e:any) => {
                  //setSelectedCategoria( e.target.value )
                  changeCategoria( e.target.value)
                  
                }}
                inputProps={{
                  name: 'categoria',
                  id: 'tipo-select',
                }}
              >
                {categoriasToShow.map((cat: { _id: any; nome: any; }) => (
                  <MenuItem className={'text'} key={cat._id} value={cat._id}>
                    {cat.nome}
                  </MenuItem>
                ))}
              </Select>
              {
                errorMessageLocal ? (<div style={{color:'red', backgroundColor:'black', margin:15}}>
                  {errorMessageLocal}
                </div>) : null
              }
              {
                selectedNewCategoria ? (
                  <div style={{display: 'flex',
                              flexDirection: 'column',
                              flexWrap: 'nowrap',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: 15,
                              }}>
                    Categoria destino: {getCategoriaNome(selectedNewCategoria)}
                    
                    {
                      sending ? (
                        <div style={{margin:30, fontSize:18}}>
                          enviando {index+1} de {itensSendList.length}
                        </div>
                      ) : null
                    }
                    {
                      showMessage ? (
                        <div style={{margin:30, fontSize:18}}>
                          envio concluído
                      </div>
                      ) : null
                    }
                    {
                      !showMessage&&!sending ? 
                      (<Button className='text' onClick={handleConcluir}>Finalizar</Button>) : null
                    }
                    
                  </div>
                ) : null
              }

            </FormControl>
            </div>
            ) : (
            <Box>
            <FormGroup >
            <FormControlLabel control={
              <Checkbox
                      classes={{ indeterminate: classes.indeterminateColor }}
                      checked={isAllSelected}
                      value={'all'}
                      indeterminate={
                        selected.length > 0 && selected.length < produtosList.length
                      }
                      onChange={handleChange}
              />} label="Todos" />
            {produtosList.length ? (
              produtosList.map(
                (item:any)=>
                  <div className='text'>
                    <FormControlLabel control={<Checkbox value={item.nome} onChange={handleChange} checked={selected.indexOf(item.nome) > -1} />} label={item.nome} />
                  </div>
              )
              
            ) : (<div>
              Nenhum ítem encontrado
              </div>) }
            </FormGroup>
          </Box>)
          }
          

        </DialogContent>
        <DialogActions>
          
          {
            !newUnidadeVisible ? (
              <Button disabled={!selected.length} onClick={() => {
                handleSelectCategoria()
              }}>Transferir</Button>
            ) : null
          }
          
          <Button onClick={() => {
            setProductsModalOpen(false)
            setNewUnidadeVisible(false)
          }}>Fechar</Button>
          {/* <Button onClick={handleUpdateCategoria} color="primary">
            Atualizar
          </Button> */}
        </DialogActions>
      </Dialog>

      
{/* <Modal open={editCategoriaModalOpen} onClose={() => setEditCategoriaModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editCategoriaData.nome}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.email}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, email: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.empresa}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, empresa: e.target.value })}
    />
    <input
      type="text"
      value={editCategoriaData.cnpj}
      onChange={(e) => setEditCategoriaData({ ...editCategoriaData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateCategoria}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  errorMessage : state.categorias.errorMessage,
  empresas: state.empresas.empresas,produtosCountByCategory:state.produtos.produtosCountByCategory , isLoadingProdutosUpdate: state.produtos.isLoadingProdutosUpdate, usuario: state.user.user ,produtos: state.produtos.produtos, categorias: state.categorias.categorias, isLoadingCategoria : state.categorias.isLoadingCategoria
});

const mapDispatchToProps = {
    fetchEmpresas,
    updateProduto,
    getCategorias,
    getProdutosByCategory,
    deleteCategoria,
    createCategoria,
    updateCategoria,
    getProdutosContador,
    clearErrorMessage
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CategoriasPage);
