import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
import { getUsuarios, deleteUsuario, createUsuario, updateUsuario, getUsuariosByFilter } from '../redux/usuarios/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {  InputAdornment } from '@material-ui/core';
import { Email, Phone, Business } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Autocomplete, Divider, ListItemButton, ListSubheader, Stack } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const UsuariosPage: React.FC<PropsFromRedux> = ({ isLoadingUsuarios,isLoadingUsuariosByFilter, isLoadingCreateUsuario, usuarios, usuario, getUsuariosByFilter, getUsuarios, deleteUsuario, createUsuario, updateUsuario, fetchEmpresas, empresas}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');
  const [addUsuarioModalOpen, setAddUsuarioModalOpen] = React.useState(false);
  const [newUsuarioName, setNewUsuarioName] = React.useState('');
  const [newUsuarioPassword, setNewUsuarioPassword] = React.useState('');
  const [newUsuarioConfirmPassword, setNewUsuarioConfirmPassword] = React.useState('');
  const [newUsuarioEmail, setNewUsuarioEmail] = React.useState('');
  const [newUsuarioTelefone, setNewUsuarioTelefone] = React.useState('');
  const [newUsuarioEmpresa, setNewUsuarioEmpresa] = React.useState('');
  const [editUsuarioData, setEditUsuarioData] = React.useState<any>({});
  const [newUsuarioPermissoes, setNewUsuarioPermissoes] = React.useState<any>({});
  const [editUsuarioModalOpen, setEditUsuarioModalOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const permissoesList = ['administrador','gerente','basico']

  const [rows, setRows] = React.useState(usuarios);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});

  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const apiRef = useGridApiRef();
 
  useEffect(() => {
    if(!isLoadingUsuarios&&!isLoadingUsuariosByFilter&&usuarios.length){
      setRows(usuarios)
      apiRef.current.setColumnVisibility('_id', false);
    }
    
  }, [usuarios]);

  useEffect(() => {
    changeFilter()
    
  }, [selectedUser,startDate, endDate, ]); 

  useEffect(() => {
    setAddUsuarioModalOpen(false)
    clearNewUsuario()
    getUsuarios();
  }, [getUsuarios]);
  useEffect(() => {
    getEmpresas();
    console.log('empresas' , empresas)
  }, [getEmpresas]);

  useEffect(() => {
    if(isLoadingUsuarios){
      setLoading(true)
    }
    if(loading && !isLoadingUsuarios){
      getUsuarios();
      setLoading(false)
    }
    console.log('isLoadingUsuarios ==> ',isLoadingUsuarios)
    
  }, [isLoadingUsuarios]);

  useEffect(() => {
    if(isLoadingCreateUsuario){
      setLoading(true)
    }
    if(loading && !isLoadingCreateUsuario){
      getUsuarios();
      setLoading(false)
    }
    console.log('isLoadingCreateUsuario ==> ',isLoadingCreateUsuario)
    
  }, [isLoadingCreateUsuario]);

  const handleDeleteClick = (UsuarioId:any) => {
    console.log('UsuarioId',UsuarioId)
    setSelectedUsuario(UsuarioId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario',selectedUsuario)
    deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };

  const clearNewUsuario =() => {
    setNewUsuarioName('');
    setNewUsuarioEmail('');
    setNewUsuarioTelefone('');
    setNewUsuarioEmpresa('');
    setNewUsuarioPassword('');
    setNewUsuarioConfirmPassword('');

  }

  const makePermissoes = ()=>{
    if(newUsuarioPermissoes == 'administrador'){
      return {admin : true}
    } else if(newUsuarioPermissoes == 'basico'){
      return {basic : true}
    } else if(newUsuarioPermissoes == 'gerente'){
      return {manager : true}
    } else{
      return {}
    }
  }

  const handleAddUsuario = () => {
    const novaUsuario = {
      nome: newUsuarioName,
      email: newUsuarioEmail,
      password: newUsuarioPassword,
      confirmPassword: newUsuarioConfirmPassword,
      empresa: newUsuarioEmpresa || '635c074796a268680647cdff',
      permissoes: makePermissoes(),
      ativo: true,
    };
    console.log('novaUsuario', novaUsuario)
    createUsuario(novaUsuario);
    setAddUsuarioModalOpen(false);
    clearNewUsuario()
  };
  

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };
  
  const validateCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Verifica se possui 14 dígitos
    if (cnpj.length !== 14) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000000)
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }
    
    // Validação do primeiro dígito verificador
    let sum = 0;
    let factor = 5;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    let digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[12]) !== digit) {
      return false;
    }
    
    // Validação do segundo dígito verificador
    sum = 0;
    factor = 6;
    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[13]) !== digit) {
      return false;
    }
    
    return true;
  };
  
  
  const maskCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    
    return cnpj;
  };
  
  const validatePhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Verifica se possui 11 dígitos (considerando o DDD)
    if (phone.length !== 11) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000)
    if (/^(\d)\1+$/.test(phone)) {
      return false;
    }
    
    return true;
  };
  
  const maskPhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    
    return phone;
  };

  const handleEditUsuario = (usuario:any) => {
    console.log('usuario selecionada ==>> ', usuario)
    let perm = 'basico'
    if(usuario.permissoes?.admin) {
      perm = 'administrador'
    }else if(usuario.permissoes?.manager){
      perm = 'gerente'
    }
    setNewUsuarioPermissoes(perm)
    setPasswordOpen(false);
    setNewUsuarioPassword('')
    setNewUsuarioConfirmPassword('')
    setEditUsuarioData(usuario);
    setEditUsuarioModalOpen(true);
  };
  
  const handleUpdateUsuario = () => {
    console.log(newUsuarioPassword)
    const dataToSend = {...editUsuarioData}
    if(!newUsuarioPassword){
    console.log('editUsuarioData',editUsuarioData)
      delete dataToSend.password
    }
    dataToSend.permissoes= makePermissoes();
    console.log('dataToSend',dataToSend)
    updateUsuario(dataToSend)
    setEditUsuarioData({});
    setEditUsuarioModalOpen(false);
    // Lógica para atualizar a Usuario com os dados do modal
  };

  const validatePassword = (password: string | any[]) => {
    return password.length >= 6;
  };
  
  const validateConfirmPassword = (password: any, confirmPassword: any) => {
    return password === confirmPassword;
  };



  //init DataGrid dependencies

  const clearData = (d:any)=>{
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    if(newRow.isNew){
      delete newRow.isNew
    }
    setEditUsuarioData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const checkPermissao = (perm:any)=>{
    let permString = ''
    if(perm.admin){
      permString = 'administrador'
    } else if(perm.manager){
      permString = 'gerente'
    } else{
      permString = 'operador'
    }
    return permString

  }
 

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 140,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'permissoes',
      headerName: 'regra',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> checkPermissao(params.row.permissoes)
    },
    

    
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const cat = usuarios.filter((item:any)=>item._id==id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              //onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          
          
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
                    <Tooltip TransitionComponent={Zoom}   title="Apagar" arrow>
                      <IconButton size='small' className='iconDetail' color="primary" aria-label="Editar" >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
            label="Delete"
            onClick={()=>{
              handleDeleteClick(id)
            }}
            color="inherit"
          />,
          
        ];
      },
    },
    
    
  ];

  const hiddenFields = ['_id','id','ID'];

const getTogglableColumns = (columns: GridColDef[]) => {
  console.log('getTogglableColumns',columns)
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};

const changeFilter = ()=>{

  const objToSend = {
    startDate,
    endDate,
    empresa: usuario.empresa,
    // categoria:selectedCategory?._id || '',
    usuario : selectedUser?._id || '',
    
  }
  console.log('objToSend', objToSend)
  //getProductsByFilter(objToSend)
  getUsuariosByFilter(objToSend)

}
  
  
  
  

  return (
    <Container className={classes.container}>
      <div className='{classes.root}'>
          <div className={classes.content}>
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography variant="h4" className={'title'}>
            Gerenciar Usuários
          </Typography>
          <Button className={classes.addButton} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddUsuarioModalOpen(true)}>
            Adicionar Usuário
          </Button>


          <Box sx={{  width: '100%', height:100, }} style={{ alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
            <Stack   borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
            {/* <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:30, alignContent: 'center', justifyContent: 'space-around', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              
            <Autocomplete
                getOptionLabel={(usu:any) => usu.nome }
                //getOptionSelected={(option:any, value:any) => option.nome === value.nome }
                onChange={(event, newValue) => {
                    console.log(newValue)
                    setSelectedUser(newValue)        
                    changeFilter()
                    
                }}
                disablePortal
                id="combo-box-usuario"
                options={usuarios}
                sx={{ width: 300 }}
                renderInput={(params:any) => <TextField {...params} label="Operador" />}
              />
            
            </Box> */}
            <Box sx={{  width: '100%' }} style={{display: 'flex',flexDirection: 'row',flexWrap: 'nowrap',height:70, alignContent: 'center', justifyContent: 'center', alignSelf:'center', paddingTop:10, paddingBottom:10, borderColor:'green', borderWidth:2}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker label="Inicial" 
                          //views={["day", "month", "year"]}
                          //view={["day", "month", "year"]}
                          /* slots={{ toolbar: CustomToolbar }}
                          slotProps={{ toolbar: {} }} */
                          /* slotProps={{
                            actionBar: {
                              actions: ['clear'],
                            },
                          }} */
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            console.log('inicial',value.$d)
                            setStartDate(value.$d)
                          }} 
                        />
                  <DatePicker label="Final" 
                          format="DD/MM/YYYY"
                          onChange={(value:any)=>{
                            if(!value) return
                            const valueDate = new Date(value.$d)
                            valueDate.setHours(23)
                            valueDate.setMinutes(59)
                            valueDate.setSeconds(59)
                            //console.log('day', day)
                            console.log('final', valueDate)
                            setEndDate(valueDate)
                          }}
                        
                        />
                </DemoContainer>
              </LocalizationProvider>
              <Button className='{classes.irButton}' onClick={() => changeFilter()}>Ir</Button>
            </Box>
            
              

              

              
             
          </Stack>
          
          
          </Box> 


          {usuarios.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingUsuarios||isLoadingUsuariosByFilter}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
          disableRowSelectionOnClick
          
          onClipboardCopy={(copiedString) => console.log(copiedString)}
          unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'asc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'usuariosDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            Nehum registro encontrado
          </div>)}

          {/* {usuarios.length ? (
            <div className={'listContainer'}>
              <List>
                {usuarios.map((usuario: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <Typography className='text'>{usuario.nome}</Typography>
                    <div>
                      <IconButton color="primary" aria-label="Editar" onClick={() => handleEditUsuario(usuario)}>
                        <EditIcon />
                      </IconButton>
                      
                      <IconButton
                        color="secondary"
                        aria-label="Deletar"
                        className={classes.deleteButton}
                        onClick={() => handleDeleteClick(usuario._id)}
                      >
                        
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirmar exclusão</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Tem certeza de que deseja excluir?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancelar</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={addUsuarioModalOpen} onClose={() => setAddUsuarioModalOpen(false)}>
        <DialogTitle>Adicionar Usuario</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome do usuário"
            value={newUsuarioName}
            onChange={(e) => setNewUsuarioName(e.target.value)}
          />
          <TextField
            label="E-mail"
            value={newUsuarioEmail}
            onChange={(e) => setNewUsuarioEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(newUsuarioEmail)}
            helperText={!validateEmail(newUsuarioEmail) && 'E-mail inválido'}
          />
          <TextField
              label="Senha"
              value={newUsuarioPassword}
              onChange={(e) => {
                setNewUsuarioPassword(e.target.value)
              }}
              type="password"
              error={!validatePassword(newUsuarioPassword)}
              helperText={!validatePassword(newUsuarioPassword) && 'A senha deve ter pelo menos 6 caracteres'}
            />
            <TextField
              label="Confirmar Senha"
              value={newUsuarioConfirmPassword}
              onChange={(e) => {
                setNewUsuarioConfirmPassword(e.target.value )
              }}
              type="password"
              error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
              helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'As senhas não correspondem'}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="empresa-select">Selecione a Empresa</InputLabel>
              <Select
                value={newUsuarioEmpresa}
                onChange={(e:any) => setNewUsuarioEmpresa( e.target.value )}
                inputProps={{
                  name: 'empresa',
                  id: 'empresa-select',
                }}
              >
                {empresas.map((empresa: { _id: any; nome: any; }) => (
                  <MenuItem key={empresa._id} value={empresa._id}>
                    {empresa.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
              <Select
                value={newUsuarioPermissoes}
                onChange={(e:any) => setNewUsuarioPermissoes( e.target.value )}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

          
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddUsuarioModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleAddUsuario} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      { editUsuarioModalOpen && editUsuarioData.nome ?
      <Dialog maxWidth="lg" open={editUsuarioModalOpen} onClose={() => setEditUsuarioModalOpen(false)}>
        <DialogTitle>Editar Usuario</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome do usuário"
            value={editUsuarioData.nome}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, nome: e.target.value })}
          />
          <TextField
            label="E-mail"
            value={editUsuarioData.email}
            onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(editUsuarioData.email)}
            helperText={!validateEmail(editUsuarioData.email) && 'E-mail inválido'}
          />
          { passwordOpen ? (
            <>
              <TextField
              label="Senha"
              value={newUsuarioPassword}
              onChange={(e) => {
                setEditUsuarioData({ ...editUsuarioData, password: e.target.value })
                setNewUsuarioPassword(e.target.value)
              }}
              type="password"
              error={!validatePassword(newUsuarioPassword)}
              helperText={!validatePassword(newUsuarioPassword) && 'A senha deve ter pelo menos 6 caracteres'}
            />
            <TextField
              label="Confirmar Senha"
              value={newUsuarioConfirmPassword}
              onChange={(e) => {
                setEditUsuarioData({ ...editUsuarioData, confirmPassword: e.target.value })
                setNewUsuarioConfirmPassword(e.target.value )
              }}
              type="password"
              error={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword)}
              helperText={!validateConfirmPassword(newUsuarioPassword, newUsuarioConfirmPassword) && 'As senhas não correspondem'}
            />
            </>

          ) : (<Button onClick={() => setPasswordOpen(true)}>Alterar senha</Button>)
            
          }


            <FormControl fullWidth>
              <InputLabel htmlFor="permissao-select">Selecione o tipo de usuário</InputLabel>
              <Select
                value={newUsuarioPermissoes}
                onChange={(e:any) => setNewUsuarioPermissoes( e.target.value )}
                inputProps={{
                  name: 'permissao',
                  id: 'permissao-select',
                }}
              >
                {permissoesList.map((permissao: any) => (
                  <MenuItem key={permissao} value={permissao}>
                    {permissao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
          

            {/* <TextField
              label="Empresa"
              value={editUsuarioData.empresa}
              onChange={(e) => setNewUsuarioEmpresa(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editUsuarioData.empresa == ""}

              helperText={
                editUsuarioData.empresa=="" &&
                'Empresa inválida'
              }
            /> */}



        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditUsuarioModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateUsuario} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}

      
{/* <Modal open={editUsuarioModalOpen} onClose={() => setEditUsuarioModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editUsuarioData.nome}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.email}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, email: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.telefone}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={editUsuarioData.cnpj}
      onChange={(e) => setEditUsuarioData({ ...editUsuarioData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateUsuario}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  usuarios: state.usuarios.usuarios, 
  usuario: state.user.user,
  isLoadingUsuarios : state.usuarios.isLoadingUsuarios,
  isLoadingUsuariosByFilter : state.usuarios.isLoadingUsuariosByFilter,
  isLoadingCreateUsuario : state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas
});

const mapDispatchToProps = {
  fetchEmpresas,
  getUsuarios,
  getUsuariosByFilter,
  deleteUsuario,
  createUsuario,
  updateUsuario
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UsuariosPage);
