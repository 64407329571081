import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Typography, List, ListItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal, LinearProgress } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
import { fetchEmpresas, deleteEmpresa, createEmpresa, updateEmpresa } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { width } from '@mui/system';
import { dividerClasses } from '@mui/material';
import { Autocomplete, Divider, ListItemButton, ListSubheader, Stack } from '@mui/material';
import moment from 'moment'
import {  InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, ViewHeadline} from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100vh',
  },
  content: {
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
    marginBottom:20,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

type PropsFromRedux = ConnectedProps<typeof connector>;

const EmpresasPage: React.FC<PropsFromRedux> = ({ isLoadingEmpresa, empresas,usuario, fetchEmpresas, deleteEmpresa, createEmpresa, updateEmpresa}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedEmpresa, setSelectedEmpresa] = React.useState('');
  const [addEmpresaModalOpen, setAddEmpresaModalOpen] = React.useState(false);
  const [newEmpresaName, setNewEmpresaName] = React.useState('');
  const [newEmpresaEmail, setNewEmpresaEmail] = React.useState('');
  const [newEmpresaTelefone, setNewEmpresaTelefone] = React.useState('');
  const [newEmpresaCNPJ, setNewEmpresaCNPJ] = React.useState('');
  const [editEmpresaData, setEditEmpresaData] = React.useState<any>({});
  const [editEmpresaModalOpen, setEditEmpresaModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [selectedUser, setSelectedUser] = React.useState<any>({key:0,value:'Filtro por operador'});
  const [rows, setRows] = React.useState(empresas);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const apiRef = useGridApiRef();
 

  useEffect(() => {
    setAddEmpresaModalOpen(false)
    fetchEmpresas();
  }, [fetchEmpresas]);
  useEffect(() => {
    setRows(empresas)
    if(!isLoadingEmpresa&&empresas.length){
      apiRef.current.setColumnVisibility('_id', false);
    }
  }, [empresas]);

  useEffect(() => {
    if(save){
      handleUpdateEmpresa()
      setSave(false)
    }
    console.log('rows useEffect' , rows)
  }, [rows]); 

  useEffect(() => {
    if(isLoadingEmpresa){
      setLoading(true)
    }
    if(loading && !isLoadingEmpresa){
      fetchEmpresas();
      setLoading(false)
    }
    console.log('isLoadingEmpresa ==> ',isLoadingEmpresa)
    
  }, [isLoadingEmpresa]);

  const handleDeleteClick = (empresaId: any) => {
    setSelectedEmpresa(empresaId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    deleteEmpresa(selectedEmpresa);
    setDeleteConfirmationOpen(false);
  };

  const handleAddEmpresa = () => {
    const novaEmpresa = {
      nome: newEmpresaName,
      email: newEmpresaEmail,
      telefone: newEmpresaTelefone,
      cnpj: newEmpresaCNPJ,
      ativo: true,
    };
  
    createEmpresa(novaEmpresa);
    setAddEmpresaModalOpen(false);
    setNewEmpresaName('');
    setNewEmpresaEmail('');
    setNewEmpresaTelefone('');
    setNewEmpresaCNPJ('');
  };
  

  const validateEmail = (email:any) => {
    return true
    // Implemente a lógica de validação do e-mail aqui
  };
  
  const validateCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Verifica se possui 14 dígitos
    if (cnpj.length !== 14) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000000)
    if (/^(\d)\1+$/.test(cnpj)) {
      return false;
    }
    
    // Validação do primeiro dígito verificador
    let sum = 0;
    let factor = 5;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    let digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[12]) !== digit) {
      return false;
    }
    
    // Validação do segundo dígito verificador
    sum = 0;
    factor = 6;
    for (let i = 0; i < 13; i++) {
      sum += parseInt(cnpj[i]) * factor;
      factor = factor === 2 ? 9 : factor - 1;
    }
    
    digit = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (parseInt(cnpj[13]) !== digit) {
      return false;
    }
    
    return true;
  };
  
  
  const maskCNPJ = (cnpj:any) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    
    return cnpj;
  };
  
  const validatePhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Verifica se possui 11 dígitos (considerando o DDD)
    if (phone.length !== 11) {
      return false;
    }
    
    // Verifica se todos os dígitos são iguais (ex: 00000000000)
    if (/^(\d)\1+$/.test(phone)) {
      return false;
    }
    
    return true;
  };
  
  const maskPhone = (phone:any) => {
    // Remove caracteres não numéricos
    phone = phone.replace(/[^\d]+/g, '');
    
    // Aplica a máscara
    phone = phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    
    return phone;
  };

  const handleEditEmpresa = (empresa:any) => {
    console.log('empresa selecionada ==>> ', empresa)
    setEditEmpresaData(empresa);
    setEditEmpresaModalOpen(true);
  };
  
  const handleUpdateEmpresa = () => {
    console.log('editEmpresaData',editEmpresaData)
    if(editEmpresaData.updatedAt) delete editEmpresaData.updatedAt
    if(editEmpresaData.createdAt) delete editEmpresaData.createdAt
    updateEmpresa(editEmpresaData)
    setEditEmpresaData({});
    setEditEmpresaModalOpen(false);
    // Lógica para atualizar a empresa com os dados do modal
  };

  
  //init DataGrid dependencies

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
      newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
  }

  const _ = (id: GridRowId) => () => {
    setRows(rows.filter((row:any) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row:any) => row._id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row:any) => row._id !== id));
    }
  };

  const processRowUpdate:any = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log('newRow',newRow)
    if(newRow.isNew){
      delete newRow.isNew
    }
    setEditEmpresaData(newRow)
    setRows(rows.map((row:any) => (row._id === newRow._id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
 

  const columns: GridColDef[] = [
    { field: '_id', headerName: 'ID', width: 90 },
    {
      field: 'createdAt',
      headerName: 'Data',
      type:'Date',
      width: 140,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> clearData(params.row.createdAt)
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 150,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 140,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      width: 140,
      editable: true,
      //valueGetter : (params: GridValueGetterParams)=> getCategoriaNome(params.row.categoria)
    },
    /* {
      field: 'usuario',
      headerName: 'Operador',
      width: 120,
      editable: false,
      valueGetter : (params: GridValueGetterParams)=> params.row.usuario?.nome || params.row.usuario
    }, */
    

    
    /* {
      field: 'identificacao',
      headerName: 'código',
      description: 'código do item',
      sortable: false,
      width: 140,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].codigo || ''}  `,
    }, 
    {
      field: 'updatedAt',
      headerName: 'tipo identificacao',
      description: 'tipo de identificacao',
      sortable: false,
      width: 75,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.identificacao[0].tipo || ''} `,
    }, */
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ações',
      width: 150,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        //const cat = categoriasList.filter((item:any)=>item._id==id)[0]
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          
          
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={()=>{
              handleDeleteClick(id)
            }}
            color="inherit"
          />,
          
        ];
      },
    },
    
    
  ];

  const hiddenFields = ['_id','id','ID'];

const getTogglableColumns = (columns: GridColDef[]) => {
  console.log('getTogglableColumns',columns)
  return columns
    .filter((column) => !hiddenFields.includes(column.field))
    .map((column) => column.field);
};

interface AutocompleteOption {
  categoria: string;
}

const clearData = (d:any)=>{
  const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
  return dateTransform
}

const changeFilter = ()=>{

  const objToSend = {
    //startDate,
    //endDate,
    empresa: usuario.empresa,
    //categoria:selectedCategory?._id || '',
    usuario : selectedUser?._id || '',
    //produto : selectedProduto?._id || '',
  }
  console.log('objToSend', objToSend)
  //getInventoriesByFilter(objToSend)

}

const handleSaveClick = (id: GridRowId) => () => {
  const el = rows.filter((item:any)=>item._id == id)
  console.log('handleSaveClick', id)
  console.log('handleSaveClick', el)
  setSave(true)
  setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
};
  
  
  

  return (
    <Container className={classes.container}>
      <div className='{classes.root}'>
          <div className={classes.content}>
          {loading ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      ) : null}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
          <Typography variant="h4" className={'title'}>
            Gerenciar Empresas
          </Typography>
          <Button className={classes.button} variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setAddEmpresaModalOpen(true)}>
            Adicionar Empresa
          </Button>
          {empresas.length ? (
            <Box sx={{  width: '100%' }}>
              
            <DataGrid
              apiRef={apiRef}
              loading={isLoadingEmpresa}
              getRowId={(row: { _id: any; }) => row._id}
              //checkboxSelection
              disableRowSelectionOnClick
              
              onClipboardCopy={(copiedString) => console.log(copiedString)}
              unstable_ignoreValueFormatterDuringExport
              autoHeight={true}
              rows={rows}
              columns={columns}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'createdAt', sort: 'asc' }],
                },
                
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[5]}
              //checkboxSelection
              
              //editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              // slots={{
              //   toolbar: EditToolbar,
              // }}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: { setRows, setRowModesModel,csvOptions:{
                  fileName: 'unidadesDataBase_',
                  delimiter: ';',
                  utf8WithBom: true,
                },
                columns: {
                  columnVisibilityModel:{ID: false,},
                },
                columnsPanel: {
                  getTogglableColumns,
                },
                printOptions: {hideToolbar:true, disableToolbarButton: false }},
              }}

              
       
              //toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        
        
            />
              
          </Box>
          ):(<div style={{margin:15}}>
            Nehum registro encontrado
          </div>)}
          {/* {empresas.length ? (
            <div className={'listContainer'}>
              <List>
                {empresas.map((empresa: any, index: any) => (
                  <ListItem key={index} className={classes.listItem} divider>
                    <Typography className='text'>{empresa.nome}</Typography>
                    <div>
                      <IconButton color="primary" aria-label="Editar" onClick={() => handleEditEmpresa(empresa)}>
                        <EditIcon />
                      </IconButton>
                      
                      <IconButton
                        color="secondary"
                        aria-label="Deletar"
                        className={classes.deleteButton}
                        onClick={() => handleDeleteClick(empresa.id)}
                      >
                        
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
            
          ) : null} */}
          <Button className='backbutton' onClick={() => navigate(-1)}>Voltar</Button>
        </div>
      </div>
      
      <Dialog open={addEmpresaModalOpen} onClose={() => setAddEmpresaModalOpen(false)}>
        <DialogTitle>Adicionar Empresa</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Empresa"
            value={newEmpresaName}
            onChange={(e) => setNewEmpresaName(e.target.value)}
          />
          <TextField
            label="E-mail"
            value={newEmpresaEmail}
            onChange={(e) => setNewEmpresaEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(newEmpresaEmail)}
            helperText={!validateEmail(newEmpresaEmail) && 'E-mail inválido'}
          />
          <TextField
            label="Telefone"
            value={newEmpresaTelefone}
            onChange={(e) => setNewEmpresaTelefone(maskPhone(e.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
            error={!validatePhone(newEmpresaTelefone)}
            helperText={!validatePhone(newEmpresaTelefone) && 'Telefone inválido'}
          />
          <TextField
            label="CNPJ"
            value={newEmpresaCNPJ}
            onChange={(e) => setNewEmpresaCNPJ(maskCNPJ(e.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Business />
                </InputAdornment>
              ),
            }}
            error={!validateCNPJ(newEmpresaCNPJ)}
            helperText={!validateCNPJ(newEmpresaCNPJ) && 'CNPJ inválido'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddEmpresaModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleAddEmpresa} color="primary">
            Criar
          </Button>
        </DialogActions>
      </Dialog>
      { editEmpresaModalOpen && editEmpresaData.nome ?
      <Dialog open={editEmpresaModalOpen} onClose={() => setEditEmpresaModalOpen(false)}>
        <DialogTitle>Editar Empresa</DialogTitle>
        <DialogContent className={classes.modalContent}>
          <TextField
            label="Nome da Empresa"
            value={editEmpresaData.nome}
            onChange={(e) => setEditEmpresaData({ ...editEmpresaData, nome: e.target.value })}
          />
          <TextField
            label="E-mail"
            value={editEmpresaData.email}
            onChange={(e) => setEditEmpresaData({ ...editEmpresaData, email: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
            error={!validateEmail(editEmpresaData.email)}
            helperText={!validateEmail(editEmpresaData.email) && 'E-mail inválido'}
          />
          <TextField
            label="Telefone"
            value={editEmpresaData.telefone}
            onChange={(e) => setEditEmpresaData({...editEmpresaData, telefone : maskPhone(e.target.value)})}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
            error={editEmpresaData.telefone !== "" && !validatePhone(editEmpresaData.telefone)}

            helperText={
              editEmpresaData.telefone &&
              !validatePhone(editEmpresaData.telefone) &&
              'Telefone inválido'
            }
            />

            <TextField
              label="CNPJ"
              value={editEmpresaData.cnpj}
              onChange={(e) => setNewEmpresaCNPJ(maskCNPJ(e.target.value))}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Business />
                  </InputAdornment>
                ),
              }}
              error={editEmpresaData.cnpj !== "" && !validateCNPJ(editEmpresaData.cnpj)}

              helperText={
                editEmpresaData.cnpj &&
                !validateCNPJ(editEmpresaData.cnpj) &&
                'CNPJ inválido'
              }
            />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditEmpresaModalOpen(false)}>Cancelar</Button>
          <Button onClick={handleUpdateEmpresa} color="primary">
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>:null}

      
{/* <Modal open={editEmpresaModalOpen} onClose={() => setEditEmpresaModalOpen(false)}>
  <div>
    <input
      type="text"
      value={editEmpresaData.nome}
      onChange={(e) => setEditEmpresaData({ ...editEmpresaData, nome: e.target.value })}
    />
    <input
      type="text"
      value={editEmpresaData.email}
      onChange={(e) => setEditEmpresaData({ ...editEmpresaData, email: e.target.value })}
    />
    <input
      type="text"
      value={editEmpresaData.telefone}
      onChange={(e) => setEditEmpresaData({ ...editEmpresaData, telefone: e.target.value })}
    />
    <input
      type="text"
      value={editEmpresaData.cnpj}
      onChange={(e) => setEditEmpresaData({ ...editEmpresaData, cnpj: e.target.value })}
    />
    <button onClick={handleUpdateEmpresa}>Atualizar</button>
  </div>
</Modal> */}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  empresas: state.empresas.empresas, usuario: state.user.user, isLoadingEmpresa : state.empresas.isLoadingEmpresa
});

const mapDispatchToProps = {
  fetchEmpresas,
  deleteEmpresa,
  createEmpresa,
  updateEmpresa
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmpresasPage);
